/* eslint-disable no-unused-vars */
/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingSignPrivacy
 * path: /onboarding/makler/sign-dsgvo
 *
 * this is a page that shows signing for privacy agreement
 */

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
// import SignatureCanvas from 'react-signature-canvas';
import { isMobile } from 'react-device-detect';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/V2/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
// import YoutubeVideo from '../../components/elements/YoutubeVideo';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import YesNo from '../../components/forms/YesNo';
import Utils from '../../lib/Utils';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import config from '../../config';
import TextsFromFile from '../../components/V2/TextFromFile';
import terms from '../../trans/lang/de.terms-app';
import '../../styles/V1/onboarding.scss';
import '../../styles/V1/app.scss';

const SignPrivacyPage = observer(({ UserStore, ContractsStore }) => {
    //state variables
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isSigned, setIsSign] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isNewService, setIsNewService] = useState(false);
    const [isMarketing, setIsMarketing] = useState(false);

    //ref variable for popup
    // const refSign = useRef();
    const refAlert = useRef();

    //get customer
    // const { customer, isCompany } = UserStore;

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //remove sign
    // const removeSign = () => {
    //     refSign.current.clear();
    //     setIsSign(false);
    // };

    //starting signing
    // const onStartSign = () => {
    //     setIsSign(true);
    // };

    // //component did mount handler
    // useEffect(() => {
    //     const onWindowResize = () => {
    //         const canvas = refSign.current.getCanvas();
    //         canvas.width = canvas.parentElement.clientWidth;
    //         canvas.height = canvas.parentElement.clientHeight;
    //     };
    //     onWindowResize();
    //     window.addEventListener('resize', onWindowResize);
    //     return () => window.removeEventListener('resize', onWindowResize);
    // }, []);

    //handling next button click
    const next = async () => {
        // setIsLoading(true);
        const signBrokerBase64 = state && state.hasOwnProperty('signBase64') ? state.signBase64 : '';
        // let signature = refSign.current.getTrimmedCanvas().toDataURL('image/png');
        // signature = await Utils.resizeBase64Image(signature);

        if (!signBrokerBase64) {
            setIsLoading(false);
            refAlert.current.error(trans.t('errors', 'firstSignIsEmpty'));
            return false;
        }
        // if (!signature) {
        //     setIsLoading(false);
        //     refAlert.current.error(trans.t('errors', 'secondSignIsEmpty'));
        //     return false;
        // }

        const signature = signBrokerBase64.substring(21);
        // signature = signature.substring(21);

        const res = await UserStore.signBroker({
            signBrokerBase64: signature,
            signPrivacyBase64: signature,
            isContactingAccepted: isContact,
            isNewServiceAccepted: isNewService,
            isMarketingAccepted: isMarketing,
            contractIds: ContractsStore.checkedIds,
            brokerType: ContractsStore.tempContractType,
        });
        if (!res.status) {
            setIsLoading(false);
            refAlert.current.error(res.msg);
            return false;
        }
        ContractsStore.setCheckedIds([]);
        setIsLoading(false);

        navigate(pageUrl.onboardingContractAdd);
    };

    // const canvasProps = { height: 260, className: 'signCanvas' };
    // if (isMobile) {
    //     canvasProps.width = window.innerWidth - 40;
    // }

    // let signNameText = trans.t('onboardingSignPrivacyPage', 'preNameText');
    // if (isCompany) {
    //     signNameText = customer.companyNameValue + trans.t('onboardingSignPrivacyPage', 'preNameCompanyText');
    // }

    return (
        <div className="main-wrapper no-background onboarding-sign-privacy-page">
            <Header title={trans.t('onboardingSignPrivacyPage', 'pageTitle')} noMenu noIcon backButton />
            <div className="main-content-new bottom-padding">
                {/* <h2>{trans.t('onboardingSignPrivacyPage', 'h1')}</h2> */}
                {/* <div className="top-text">{trans.t('onboardingSignPrivacyPage', 'text1')}</div>
                <div className="conditions">
                    <div className="item">
                        <div>{trans.t('onboardingSignPrivacyPage', 'contactText')}</div>
                        <YesNo defaultValue={false} onChange={(val) => setIsContact(val)} role="yesnoContact" />
                    </div>
                    <div className="item">
                        <div>{trans.t('onboardingSignPrivacyPage', 'newServiceText')}</div>
                        <YesNo defaultValue={false} onChange={(val) => setIsNewService(val)} role="yesnoNewService" />
                    </div>
                    <div className="item">
                        <div>{trans.t('onboardingSignPrivacyPage', 'marketingText')}</div>
                        <YesNo defaultValue={false} onChange={(val) => setIsMarketing(val)} role="yesnoMarketing" />
                    </div>
                </div> */}
                {/* <ButtonLink isTouch text={trans.t('onboardingSignPrivacyPage', 'linkText')} onClick={() => window.open(config.urls.privacyTemplate, '_blank').focus()} /> */}
                {/* <div className="container-sign">
                    <div className="inner">
                        <SignatureCanvas ref={refSign} canvasProps={canvasProps} onBegin={onStartSign} />
                    </div>
                    <div className="container-sign-bottom">
                        <div className="name">
                            {signNameText} {customer.fullName}
                        </div>
                        <div className="remove-sign" onClick={removeSign} />
                    </div>
                </div> */}
                {/* <div className="bottom-text">{trans.t('onboardingSignPrivacyPage', 'text2')}</div> */}
                <TextsFromFile deText={terms} />
            </div>
            <Bottom>
                <Button role="btnNext" title={trans.t('onboardingContractListFinishPage', 'btnNext')} onClick={next} green />
            </Bottom>
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignPrivacyPage;
