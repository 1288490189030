/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractList
 * path: /onboarding/vertrag/typ
 *
 * this is a page show added contracts and list of products in onboarding proccess
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { v4 as getUid } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import ButtonV2 from '../../components/V2/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
import ProviderImage from '../../components/elements/ProviderImage';
import Loading from '../../components/elements/Loading';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import Utils from '../../lib/Utils';
import config from '../../config';
import '../../styles/V1/terms.scss';

//icons
import IconUmbrella from '../../assets/products/umbrella.svg';
import IconBandair from '../../assets/products/bandair.svg';
import IconCar from '../../assets/products/car.svg';
import IconCardio from '../../assets/products/cardio.svg';
import IconDocsuitcase from '../../assets/products/docsuitcase.svg';
import IconDog from '../../assets/products/dog.svg';
// import IconFence from '../../assets/products/fence.svg';
import IconHouse from '../../assets/products/house.svg';
import IconLibra from '../../assets/products/libra.svg';
import IconPatient from '../../assets/products/patient.svg';
import IconShield from '../../assets/products/shield.svg';
import '../../styles/V1/app.scss';
import Tooltip from '../../components/V2/Tooltip';
import { TEMP_CONTRACT_TYPE } from '../../const/tempContractType';
import Popup from '../../components/popups/Popup';

const ContractListPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [, setReload] = useState(0);
    const [deleteContract, setDeleteContract] = useState();
    const [confirmContracts, setConfirmContracts] = useState(false);

    //ref variable for popup
    const refAlert = useRef();

    //get prop 'isContractAdded' from another screen
    const { state } = useLocation();
    const { tempContractType } = ContractsStore;
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get variable to understand that user authorized through login form
    const { isCompany, isAuthorized, customer } = UserStore;

    //preloaded product types that needs to be shown as list of buttons
    const productsObjPrivate = {
        private_liability: IconUmbrella,
        car_liability_insurance: IconCar,
        home_content_insurance: IconHouse,
        // building_legal_insurance: IconFence,
        legal_expense_insurance: IconLibra,
        accident_insurance: IconBandair,
        disability: IconPatient,
        // public_health_insurance: IconDocsuitcase,
        private_health_insurance: IconDocsuitcase,
        private_pension_insurance: IconShield,
        term_life_insurance: IconCardio,
        denture: IconDocsuitcase,
        supplementary_health_insurance: IconDocsuitcase,
        liability_insurance_for_dogs: IconDog,
        homeowners_insurance: IconHouse,
    };

    const stromObj = {
        power: IconHouse,
        gas: IconHouse,
    };
    // const productsObjCompany = {
    //     closure_of_insurance: trans.t('onboardingContractListPage', 'closure_of_insurance'),
    //     public_liability_insurance: trans.t('onboardingContractListPage', 'public_liability_insurance'),
    //     betriebsunterbrechungsversicherung: trans.t('onboardingContractListPage', 'betriebsunterbrechungsversicherung'),
    //     cyber: trans.t('onboardingContractListPage', 'cyber'),
    //     robbery_insurance: trans.t('onboardingContractListPage', 'robbery_insurance'),
    //     business_car_insurance: trans.t('onboardingContractListPage', 'business_car_insurance'),
    //     business_law_insurance: trans.t('onboardingContractListPage', 'business_law_insurance'),
    //     fidelity_insurance: trans.t('onboardingContractListPage', 'fidelity_insurance'),
    //     landwirtschaftliche_feuerversicherung: trans.t('onboardingContractListPage', 'landwirtschaftliche_feuerversicherung'),
    //     truck_insurance: trans.t('onboardingContractListPage', 'truck_insurance'),
    //     machine_insurance: trans.t('onboardingContractListPage', 'machine_insurance'),
    //     property_loss_insurance: trans.t('onboardingContractListPage', 'property_loss_insurance'),
    // };
    const productsObj = tempContractType === 'insurance' ? productsObjPrivate : stromObj;

    //click handler for clicking on product category
    const openProviderPage = (productId) => {
        ContractsStore.startAddingContract();
        if (!productId) {
            refAlert.current.show(trans.t('onboardingContractListPage', 'errorNoProductInList'), 'error');
            return null;
        }
        ContractsStore.setProductTypeForAddingContract(productId);
        navigate(pageUrl.onboardingProviders);
        return true;
    };

    //handler for clicking on show all list of products
    const openProductPage = () => {
        ContractsStore.startAddingContract();
        navigate(pageUrl.onboardingProducts);
    };

    //handler for edit contract
    const editContract = (contractId) => {
        ContractsStore.editContract(contractId);
        navigate(pageUrl.onboardingProducts, { state: { contractId } });
    };

    //handler for deleteing contract
    const openDeletePopup = (contractId) => () => {
        // const index = ContractsStore.checkedIds.indexOf(contractId);
        // if (index !== -1) {
        //     setContractChecked(contractId);
        // }
        // setReload(getUid());
        // ContractsStore.deleteContract(contractId);
        setDeleteContract(contractId);
    };

    //handler for deleteing contract
    const undeleteContract = (contractId) => {
        ContractsStore.undeleteContract(contractId);
        setReload(getUid());
    };

    //handler checked the checkbox
    // const setContractChecked = (contractId, isDisabled) => {
    //     if (isDisabled) {
    //         return false;
    //     }
    //     const newCheckedIds = [...ContractsStore.checkedIds];
    //     const index = newCheckedIds.indexOf(contractId);
    //     if (index !== -1) {
    //         newCheckedIds.splice(index, 1);
    //     } else {
    //         newCheckedIds.push(contractId);
    //     }
    //     ContractsStore.setCheckedIds(newCheckedIds);
    //     return true;
    // };

    //handler for "next" button
    const next = async () => {
        const allContracts = getContracts();
        const newCheckedIds = allContracts
            .filter((contract) => contract.isPossibleToSelectOnboarding && !contract.isDeleted)
            .map((contract) => contract.idValue);

        ContractsStore.addTempContractForBroker(newCheckedIds);
        ContractsStore.setCheckedIds(newCheckedIds);

        // TODO: align with brokerType (isHaveBroker is for insurance only)
        // if there is no broker - navigate to sign the broker agreement
        // WE CAN SAVE CONTRACTS ONLY AFTER USER DATA IS SAVED
        if (isAuthorized) {
            const isPossibleToSave = (customer.isHaveBroker && tempContractType === TEMP_CONTRACT_TYPE.INSURANCE) || (customer.isHaveEnergyBroker && tempContractType === TEMP_CONTRACT_TYPE.ENERGY);

            setIsLoading(true);
            ContractsStore.addTempContractForBroker(newCheckedIds);
            let res = await ContractsStore.saveTempContracts(customer.id);
            if (!res.status) {
                setIsLoading(false);
                refAlert.current.error(res.msg);
                return null;
            }
            setIsLoading(false);

            if (isPossibleToSave) {
                setIsLoading(true);
                res = await ContractsStore.activateBrokerForCheckedContracts(customer.id);

                if (!res.status) {
                    refAlert.current.error(res.msg);
                    setIsLoading(false);
                    return null;
                }
                setIsLoading(false);

                ContractsStore.clearOnboardingContracts();
                navigate(pageUrl.secureDashboard);

                return true;
            } else {
                const nextRoute = tempContractType === 'insurance' ? pageUrl.onboardingSignBrokerInsurance : pageUrl.onboardingSignBrokerEnergy;
                navigate(nextRoute);
            }
        }

        if (customer.fullName) {
            setIsLoading(true);
            ContractsStore.addTempContractForBroker(newCheckedIds);
            const res = await ContractsStore.saveTempContracts(customer.id);
            if (!res.status) {
                setIsLoading(false);
                refAlert.current.error(res.msg);
                return null;
            }
            setIsLoading(false);

            const nextRoute = tempContractType === 'insurance' ? pageUrl.onboardingSignBrokerInsurance : pageUrl.onboardingSignBrokerEnergy;
            navigate(nextRoute);
        } else {
            navigate(pageUrl.onboardingUserData);
        }

        return true;
    };

    //determine is it for finapi list or for manual
    const isFinapi = state && state.hasOwnProperty('isFinapi') && state.isFinapi;

    //manual contracts
    const getContracts = () => {
        // if (tempContractType === TEMP_CONTRACT_TYPE.INSURANCE) {
        //     return ContractsStore.getInsuranceContracts(true);
        // }

        // if (tempContractType === TEMP_CONTRACT_TYPE.ENERGY) {
        //     return ContractsStore.getStromContracts(true);
        // }

        return ContractsStore.getTempContracts();
    };

    const isNextButton = !!getContracts().length;

    const renderContract = (contract, index) => {
        // const isChecked = ContractsStore.checkedIds.indexOf(contract.idValue) !== -1;

        const providerObj = DataStore.getProvider(contract.providerValue);

        return (
            <div className={!contract.isDeleted ? 'item' : 'item deleted'} key={index}>
                <ProviderImage imageSrc={providerObj.logoUrl} title={providerObj.titleValue} isHeaderStyle />
                <div className="texts">
                    <div className="product">{DataStore.getProductTypeTitle(contract.productTypeValue)}</div>
                    <div className="provider">
                        {contract.priceValue ? contract.priceTitle + ' ' + config.currency.title + ' ' + DataStore.getPaymentMethodTitle(contract.paymentMethodId) + ' - ' : ''}
                        {providerObj.titleValue ? Utils.addDotsToString(providerObj.titleValue) : ''}
                        {contract.numberValue ? (
                            <span className="contract-number">
                                {trans.t('onboardingContractListPage', 'contractNumber')} : {contract.numberValue}
                            </span>
                        ) : null}
                    </div>
                    <div className="edit-link">
                        {!contract.isDeleted && (
                            <ButtonLink
                                text={trans.t('onboardingContractListPage', contract.providerWithCustomValue ? 'btnEditLink' : 'btnEditRedLink')}
                                onClick={() => editContract(contract.idValue)}
                                isTouch
                                isBlue={!!contract.providerWithCustomValue}
                                isRed={!contract.providerWithCustomValue}
                            />
                        )}
                        {!contract.isDeleted && (
                            <ButtonLink
                                text={trans.t('onboardingContractListPage', 'btnDeleteLink')}
                                onClick={openDeletePopup(contract.idValue)}
                                isTouch
                                isBlue
                                className="padding-left-16"
                            />
                        )}
                        {contract.isDeleted && (
                            <ButtonLink text={trans.t('onboardingContractListPage', 'btnUnDeleteLink')} onClick={() => undeleteContract(contract.idValue)} isTouch isBlue />
                        )}
                    </div>
                </div>
                {/* <div className={isError && !isChecked ? 'selecting red' : 'selecting'}>
                    <div
                        role={'checkbox-' + index}
                        className={!contract.isPossibleToSelectOnboarding || contract.isDeleted ? 'checkbox disable' : 'checkbox'}
                        onClick={() => setContractChecked(contract.idValue, !contract.isPossibleToSelectOnboarding || contract.isDeleted)}
                    >
                        <div className="checkbox-inner">
                            <div className={isChecked ? 'element checked' : 'element'} />
                        </div>
                    </div>
                    <div className={'text' + (!contract.isPossibleToSelectOnboarding || contract.isDeleted ? ' text-disable' : '')}>
                        {trans.t('onboardingContractListPage', 'textSelectBroker')}
                    </div>
                </div> */}
            </div>
        );
    };

    const preBtnClass = isCompany ? 'margin-top-16 no-circle-border no-bold' : 'margin-top-16';

    return (
        <div className="main-wrapper no-background onboarding-contract-list-page">
            <Header backButton noMenu title="Produkte" />
            <div className="main-content-new padding-bottom-30">
                <Tooltip position="top">
                    Welche Verträge möchtest du hinzufügen?
                </Tooltip>
                {!!getContracts().length && (
                    <div className="contracts">{getContracts().map((contract, index) => renderContract(contract, index))}</div>
                )}
                {!isFinapi ? (
                    <div className="products">
                        {Object.keys(productsObj).map((productId, index) => (
                            <Button
                                key={index}
                                role={productId}
                                text={DataStore.getProductTypeTitle(productId)}
                                onClick={() => openProviderPage(productId)}
                                leftIcon={productsObj[productId]}
                                isRightIcon
                                isWhite
                                isLeftAlign
                                isFullWidth
                                height={70}
                                className={preBtnClass}
                            />
                        ))}
                        {tempContractType === 'insurance' && <ButtonLink text={trans.t('onboardingContractListPage', 'btnShowListProducts')} onClick={openProductPage} className="margin-top-16" isTouch />}
                    </div>
                ) : null}
            </div>
            {isNextButton && (
                <Bottom>
                    <ButtonV2
                        role="btnNext"
                        title={trans.t('onboardingUserDataPage', 'btnNext')}
                        onClick={() => setConfirmContracts(true)}
                        green
                    />
                </Bottom>
            )}
            <Popup
                title="Willst Du wirklich Deinen Vertrag löschen?"
                isShow={!!deleteContract}
                leftBtnTitle="Abbrechen"
                rightBtnTitle="Ja"
                redButton
                isAutoHeight
                leftFunc={() => setDeleteContract(null)}
                rightFunc={() => {
                    ContractsStore.deleteContract(deleteContract);
                    setDeleteContract(null);
                }}
            />
            <Popup
                title="Hast du all deine Verträge hinzugefügt?"
                isShow={confirmContracts}
                leftBtnTitle="Nein"
                rightBtnTitle="Ja"
                greenButton
                isAutoHeight
                leftFunc={() => setConfirmContracts(false)}
                rightFunc={next}
            />
            <BottomLinks />
            {/* <Alert ref={refAlert} message={state && state.isContractAdded ? trans.t('onboardingContractListPage', 'successAddedMsg') : ''} /> */}
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractListPage;
