/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingUserData
 * path: /onboarding/makler/verifizierung
 *
 * this is a page that shows the form to edit user data, after this page we need to store all users data and contracts into server, to not lose these data
 */

import React, { useState, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import InputText from '../../components/forms/InputText';
import InputDate from '../../components/forms/InputDate';
import Select from '../../components/forms/Select';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/V2/Button';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import Tooltip from '../../components/V2/Tooltip';
import pageUrl from '../../const/pages';
import Utils from '../../lib/Utils';
import Validation from '../../lib/Validation';
import trans from '../../trans';
import '../../styles/V1/onboarding.scss';
import '../../styles/V1/app.scss';

const UserDataPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //get contractTemp
    const { customer } = UserStore;
    const { tempContractType } = ContractsStore;

    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [salutation, setSalutation] = useState(customer.salutationValue);
    const [firstName, setFirstName] = useState(customer.firstNameValue);
    const [lastName, setLastName] = useState(customer.lastNameValue);
    const [birthdate, setBD] = useState(customer.birthdateValue);
    const [email, setEmail] = useState(customer.emailValue);
    const [phoneCode, setPhoneCode] = useState(customer.phoneCodeValue);
    const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumberValue);

    //ref variable for popup
    const refSalutation = useRef();
    const refFirstName = useRef();
    const refLastName = useRef();
    const refBD = useRef();
    const refEmail = useRef();
    const refPhoneCode = useRef();
    const refPhoneNumber = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //handling next button click
    const next = async () => {
        let formValidated = true;

        let validObj = refSalutation.current.validate();
        if (!validObj.isValid) {
            refSalutation.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refFirstName.current.validate();
        if (!validObj.isValid) {
            refFirstName.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refLastName.current.validate();
        if (!validObj.isValid) {
            refLastName.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refBD.current.validate();
        if (!validObj.isValid) {
            refBD.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        // validObj = refAddress.current.validate();
        // if (!validObj.isValid) {
        //     refAddress.current.setValidation(validObj.isValid, validObj.validateMsg);
        //     formValidated = false;
        // }

        // validObj = refPostcode.current.validate();
        // if (!validObj.isValid) {
        //     refPostcode.current.setValidation(validObj.isValid, validObj.validateMsg);
        //     formValidated = false;
        // }

        // validObj = refCity.current.validate();
        // if (!validObj.isValid) {
        //     refCity.current.setValidation(validObj.isValid, validObj.validateMsg);
        //     formValidated = false;
        // }

        validObj = refEmail.current.validate();
        if (!validObj.isValid) {
            refEmail.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refPhoneNumber.current.validate();
        if (!validObj.isValid) {
            refPhoneNumber.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        // if (isCompany) {
        //     validObj = refPosition.current.validate();
        //     if (!validObj.isValid) {
        //         refPosition.current.setValidation(validObj.isValid, validObj.validateMsg);
        //         formValidated = false;
        //     }

        //     validObj = refCompanyName.current.validate();
        //     if (!validObj.isValid) {
        //         refCompanyName.current.setValidation(validObj.isValid, validObj.validateMsg);
        //         formValidated = false;
        //     }
        // }

        if (!formValidated) {
            return false;
        }

        setIsLoading(true);
        const customerRes = await UserStore.upsertCustomer({
            salutation,
            firstName,
            lastName,
            birthdate,
            email,
            phoneCode,
            phoneNumber,
        });
        if (!customerRes.status) {
            setIsLoading(false);
            refAlert.current.error(customerRes.msg);
            return false;
        }

        const contractsRes = await ContractsStore.saveTempContracts(customerRes.data.customer.customerId);
        if (!contractsRes.status) {
            setIsLoading(false);
            refAlert.current.error(contractsRes.msg);
            return false;
        }

        if (tempContractType === 'insurance') {
            navigate(pageUrl.onboardingSignBrokerInsurance);
        } else {
            navigate(pageUrl.onboardingSignBrokerEnergy);
        }
        // navigate(pageUrl.onboardingSignPrivacy);

        return true;
    };

    const salutationOptions = useMemo(() => {
        const translations = {
            mr: 'Männlich',
            mrs: 'Weiblich',
            '': 'Divers',
        };

        return DataStore.salutationList.map((salutation) => {
            return {
                value: salutation.value,
                label: translations[salutation.value],
            };
        });
    }, [DataStore.salutationList]);

    return (
        <div className="main-wrapper no-background onboarding-user-data-page">
            <Header noMenu backButton title="User Data" />
            <div className="main-content-new">
                {/* {isCompany && (
                        <InputText
                            ref={refCompanyName}
                            role="addressId"
                            title={trans.t('onboardingUserDataPage', 'companyName')}
                            defaultValue={customer.companyNameValue}
                            onChange={(val) => setCompanyName(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.emptyValue(val)}
                        />
                    )}
                    {isCompany && (
                        <InputText
                            ref={refAddress}
                            role="addressId"
                            title={trans.t('onboardingUserDataPage', 'addressCompany')}
                            defaultValue={customer.addressValue}
                            onChange={(val) => setAddress(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.address(val)}
                        />
                    )}
                    {isCompany && (
                        <InputText
                            ref={refPostcode}
                            role="postcodeId"
                            title={trans.t('onboardingUserDataPage', 'postcodeCompany')}
                            defaultValue={customer.postcodeValue}
                            onChange={(val) => setPostcode(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.postcode(val, config.validationCountryCode)}
                        />
                    )}
                    {isCompany && (
                        <InputText
                            ref={refCity}
                            role="cityId"
                            title={trans.t('onboardingUserDataPage', 'cityCompany')}
                            defaultValue={customer.cityValue}
                            onChange={(val) => setCity(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.city(val)}
                        />
                    )}
                    {isCompany && <h1>{trans.t('onboardingUserDataPage', 'h1')}</h1>}
                    {isCompany && (
                        <InputText
                            ref={refPosition}
                            role="addressId"
                            title={trans.t('onboardingUserDataPage', 'position')}
                            defaultValue={customer.positionValue}
                            onChange={(val) => setPosition(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.emptyValue(val)}
                        />
                    )} */}
                <Tooltip position="top">
                    {trans.t('onboardingUserDataPage', 'expertMsg')}
                </Tooltip>
                <Select
                    ref={refSalutation}
                    role="salutationId"
                    title={trans.t('onboardingUserDataPage', 'salutation')}
                    defaultValue={customer.salutationValue}
                    options={salutationOptions}
                    onChange={(val) => setSalutation(val)}
                    validateFunc={(val) => Validation.emptyValue(val, trans.t('validations', 'validationSalutationEmpty'))}
                />
                <InputText
                    ref={refFirstName}
                    role="firstNameId"
                    title={trans.t('onboardingUserDataPage', 'firstName')}
                    defaultValue={customer.firstNameValue}
                    onChange={(val) => setFirstName(val)}
                    className="margin-top-16"
                    validateFunc={(val) => Validation.firstName(val)}
                />
                <InputText
                    ref={refLastName}
                    role="lastNameId"
                    title={trans.t('onboardingUserDataPage', 'lastName')}
                    defaultValue={customer.lastNameValue}
                    onChange={(val) => setLastName(val)}
                    className="margin-top-16"
                    validateFunc={(val) => Validation.lastName(val)}
                />
                <InputDate
                    ref={refBD}
                    role="birthdateId"
                    title={trans.t('onboardingUserDataPage', 'bd')}
                    defaultValue={customer.birthdateValue}
                    onChange={(val) => setBD(val)}
                    className="margin-top-16"
                    validateFunc={(val) => Validation.birthdate(val)}
                />
                {/* {!isCompany && (
                    <InputText
                        ref={refAddress}
                        role="addressId"
                        title={trans.t('onboardingUserDataPage', 'address')}
                        defaultValue={customer.addressValue}
                        onChange={(val) => setAddress(val)}
                        className="margin-top-16"
                        validateFunc={(val) => Validation.address(val)}
                    />
                )}
                {!isCompany && (
                    <InputText
                        ref={refPostcode}
                        role="postcodeId"
                        title={trans.t('onboardingUserDataPage', 'postcode')}
                        defaultValue={customer.postcodeValue}
                        onChange={(val) => setPostcode(val)}
                        className="margin-top-16"
                        validateFunc={(val) => Validation.postcode(val, config.validationCountryCode)}
                    />
                )}
                {!isCompany && (
                    <InputText
                        ref={refCity}
                        role="cityId"
                        title={trans.t('onboardingUserDataPage', 'city')}
                        defaultValue={customer.cityValue}
                        onChange={(val) => setCity(val)}
                        className="margin-top-16"
                        validateFunc={(val) => Validation.city(val)}
                    />
                )} */}
                <InputText
                    ref={refEmail}
                    role="emailId"
                    title={trans.t('onboardingUserDataPage', 'email')}
                    desc={trans.t('onboardingUserDataPage', 'emailDesc')}
                    defaultValue={customer.emailValue}
                    onChange={(val) => setEmail(val)}
                    className="margin-top-16"
                    validateFunc={(val) => Validation.email(val)}
                />
                <Select
                    ref={refPhoneCode}
                    role="phoneCodeId"
                    title={trans.t('onboardingUserDataPage', 'phoneCode')}
                    options={Utils.getPhoneCodeList()}
                    onChange={(val) => setPhoneCode(val)}
                    defaultValue={customer.phoneCodeValue}
                />
                <InputText
                    ref={refPhoneNumber}
                    role="phoneNumberId"
                    title={trans.t('onboardingUserDataPage', 'phoneNumber')}
                    desc={trans.t('onboardingUserDataPage', 'phoneNumberDesc')}
                    defaultValue={customer.phoneNumberValue}
                    onChange={(val) => setPhoneNumber(val)}
                    className="margin-top-16"
                    validateFunc={(val) => Validation.phone(val, phoneCode)}
                />
            </div>
            <div className="products-list" />
            <Bottom>
                <Button
                    role="btnNext"
                    title={trans.t('onboardingUserDataPage', 'btnNext')}
                    onClick={next}
                    green
                />
            </Bottom>
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default UserDataPage;
