/* eslint-disable max-len */
const terms = [
    //terms service
    {
        type: 'secTitle',
        text: 'Allgemeine Nutzungsbedingungen für den deutschen Markt'
    },
    {
        type: 'catTitle',
        text: 'Präambel'
    },
    {
        type: 'textTitl',
        text: 'MyContracts (nachfolgend "myContracts" oder "wir/uns" genannt) ist eine durch die myContracts Interantional Ltd. bereitgestellte und in Deutschland von ihrer deutschen Tochtergesellschaft, der Disbro GmbH, betriebene App und Internet-Plattform www.mycontracts.app. Unsere deutsche Tochtergesellschaft, die Disbro GmbH, ist als Makler für Versicherungen im Sinne des § 34d Abs. 1 Gewerbeordnung  tätig und im Handelsregister des Amtsgerichts Mannheim unter der Nummer HRB 729531 eingetragen. Sie erbringt alle Service- und Supportleistungen und berät dich auf Wunsch zu Versicherungs- und/oder Energieverträgen. Unser primäres Ziel ist es, dir Geld, Zeit und Nerven zu sparen.'
    },
    {
        type: 'subTitle',
        text: '1. Geltungsbereich'
    },
    {
        type: 'textTitl',
        text: '1.1 Mit Nutzung unserer Seite und unserer Services erklärst du dich damit einverstanden, das Angebot nur im Einklang mit diesen Geschäftsbedingungen zu nutzen. Wenn du mit diesen Geschäftsbedingungen nicht einverstanden bist, darfst du dieses Angebot nicht nutzen.'
    },
    {
        type: 'textTitl',
        text: '1.2 Die nachstehenden Allgemeinen Nutzungs- und Geschäftsbedingungen (nachfolgend auch "AGB" genannt) gelten ausschließlich für alle über die myContracts und von dir abgeschlossenen Versicherungs- und Energieverträge wie auch für sämtliche von uns zur Verfügung gestellten Leistungen, die online (z.B. im Internet) oder offline (z.B. per Telefon) angeboten bzw. zur Verfügung gestellt werden und von dir angenommen bzw. genutzt werden. Ferner gelten sie für alle sonstigen vertraglichen und vertragsähnlichen Beziehungen, die zwischen uns und dir bestehen.'
    },
    {
        type: 'textTitl',
        text: '1.3 Bei Nutzung unseres Leistungsangebots - egal ob online oder offline - gelten diese Allgemeinen Geschäftsbedingungen als verbindlich, sofern nicht etwas anderes schriftlich festgelegt wurde oder gesetzlich zwingend vorgeschrieben ist.'
    },
    {
        type: 'textTitl',
        text: '1.4 Wir müssen dir nach §15 Versicherungs-Vermittlungs-Verordnung Informationen beim ersten Kontakt geben. Diese Erstinformation bekommst du in der App oder du kannst sie unter www.mycontracts.app/Erstinformation abrufen und herunterladen.'
    },
    {
        type: 'subTitle',
        text: '2. Leistungen'
    },
    {
        type: 'textTitl',
        text: '2.1 Du kannst auf unserer Internet-Plattform, bzw. App bestehende Versicherungen und Energieverträge einfach und digital verwalten lassen oder verwalten. Du kannst eine Beratung über uns anfordern und deine Verträge optimieren oder neue Verträge abschließen. Du kannst unsere Internet-Plattform bzw. App kostenlos nutzen und die Nutzung jederzeit beenden.'
    },
    {
        type: 'textTitl',
        text: '2.2 Wenn du auf unserer Internet-Plattform bzw. App unsere Leistungen nutzt, ändert sich an deinen Verträgen zunächst nichts. Wir verändern deine Versicherungs- und Energieverträge nicht automatisch.'
    },
    {
        type: 'textTitl',
        text: '2.3 Wir werden dann als Makler für deine Versicherungsverträge tätig oder bieten dir Optimierungsmöglichkeiten für deine Energieverträge an, wenn du uns ausdrücklich beauftragst. Soweit du uns entsprechend beauftragt hast, können wir wie folgt tätig werden:'
    },
    {
        type: 'textTitl',
        text: '- Bestehende Verträge in unsere Internet-Plattform bzw. App aufnehmen und dir anzeigen,'
    },
    {
        type: 'textTitl',
        text: '- neue Verträge abschließen,'
    },
    {
        type: 'textTitl',
        text: '- bestehende Verträge ändern,'
    },
    {
        type: 'textTitl',
        text: '- Verträge kündigen.'
    },
    {
        type: 'textTitl',
        text: 'Alle Vertragsänderungen führen wir in Absprache mit dir durch.'
    },
    {
        type: 'textTitl',
        text: '2.4 Wir sind darum bemüht, dir unsere Dienste jederzeit bestmöglich zugänglich zu machen. Wir schulden oder garantieren dir aber nicht, dass du unsere Internet-Plattform bzw. App erreichen kannst. Wir haften nicht - außer im Fall eines vorsätzlichen oder grob fahrlässigen Handelns - für Ausfälle aus technischen Gründen, wie z.B. gestörte Hardware, fehlerhafte Software oder Wartungsarbeiten. Auch nicht aus Gründen, die wir nicht beeinflussen können, wie z.B. höhere Gewalt oder Verschulden durch Dritte. Die vorstehende Haftungsbeschränkung gilt nicht für Schäden an Gesundheit und Leben, oder für typischerweise vorhersehbare Schäden, die aufgrund einer Verletzung einer wesentlichen Vertragspflicht entstehen (sog. Kardinalpflichten).'
    },
    {
        type: 'textTitl',
        text: '2.5 Wir erstellen und vermitteln als Versicherungsvermittler oder Energiemakler on- oder offline für dich ausschließlich unentgeltlich und unverbindlich Auskünfte und Vergleiche über Tarife und Konditionen von mit uns kooperierenden Versicherungen und Energiedienstleistern (nachfolgend "Anbieter” genannt). Dies geschieht auf Basis einer objektiven und ausgewogenen Marktbetrachtung in die wir auch Dritte, sogenannte Analysehäuser mit einbeziehen. In den Vergleichen versuchen wir möglichst alle am Markt befindlichen Anbieter zu berücksichtigen. Bitte habe Verständnis, dass wir in Einzelfällen nicht alle Anbieter berücksichtigen können oder nicht alle Anbieter mit feien Maklern zusammenarbeiten. Die Auswahl der mit uns kooperierenden Anbieter und deren Produkte obliegt ausschließlich uns.'
    },
    {
        type: 'textTitl',
        text: '2.6 Unser Ziel ist es, dir Geld, Zeit und Nerven zu sparen. Wir schlagen dir also punktuell Optimierungsvorschläge vor. Um garantiert eine zielgerichtete Tarifauskunft oder eine Vergleichsberechnung von uns zu erhalten, musst du jedoch selbst und eigenverantwortlich auf uns zukommen.'
    },
    {
        type: 'textTitl',
        text: '2.7 Die von uns vermittelten Versicherungs-und Energieverträge kommen ausschließlich zwischen dir und dem von dir ausgewählten Anbieter zustande. Das Zustandekommen des jeweiligen Versicherungs- oder Energievertrages mit dem von dir gewählten Anbieter kann von uns nicht garantiert und nicht beeinflusst werden. Ob ein Vertrag mit dem Anbieter zustande kommt, ist abhängig von den entsprechenden Bedingungen des jeweiligen Anbieters.'
    },
    {
        type: 'textTitl',
        text: '2.8 Die konkreten Angebote der Anbieter können sich im Einzelfall hinsichtlich individueller Umstände von den einzelnen Tarifen oder Voraussetzungen, welche von uns  berechnet und angeboten werden, unterscheiden. Für Inhalt und Richtigkeit der Angebote sind ausschließlich die Anbieter verantwortlich.'
    },
    {
        type: 'textTitl',
        text: '2.9 Im Schadensfall oder Problemfall kannst du uns jederzeit über unsere App, über unsere Support Hotline unter 0621 490 73333 und über unsere Support Email Adresse service@mycontracts.app erreichen. Unser Team nimmt dann dein Anliegen auf und klärt das weitere Vorgehen mit dem Versicherer oder Energieanbieter auf Wunsch für dich.'
    },
    {
        type: 'subTitle',
        text: '3. Gewährleistung'
    },
    {
        type: 'textTitl',
        text: '3.1 Wir wenden größte Sorgfalt bei der Erstellung und Pflege der Inhalte auf unserer Webseite, der App und in unseren Services auf. Gleichwohl können wir keine Gewähr dafür übernehmen, dass die Inhalte jederzeit aktuell und für jeden individuell zutreffend sind. Für falsche Rückschlüsse und Handlungen auf Basis der Inhalte ist eine Haftung unsererseits ausgeschlossen.'
    },
    {
        type: 'textTitl',
        text: '3.2 Wenn wir deine Verträge verwalten sollen, musst du uns eine Vollmacht erteilen.Eine Vollmacht berechtigt uns unter anderem, deine bestehenden Verträge bei den Versicherern einzusehen und die Daten Ihrer bestehenden Verträge in unser System zu übertragen. Wir übernehmen keine Gewähr dafür, dass die Daten, die wir von den Versicherern erhalten, vollständig oder richtig sind.'
    },
    {
        type: 'textTitl',
        text: '3.3 Wir sind  ferner nicht dazu verpflichtet, sämtliche am Markt befindlichen Versicherungs- und Energiedienstleistungsunternehmen in die Vergleichsberechnungen einzubeziehen.'
    },
    {
        type: 'textTitl',
        text: '3.4 Wir haften nicht für die Aktualität, Vollständigkeit und Richtigkeit der durchgeführten Vorschlags- und Vergleichsberechnungen. Bitte beachte Folgendes: Im Lauf der Zeit kann sich dein Bedarf verändern. Eine bestehende Versicherung oder ein Energievertrag kann zu einem späteren Zeitpunkt deines Lebens nicht mehr deinen Bedürfnissen entsprechen. Wir übernehmen keine Gewähr dafür, dass bestehende Verträge, die wir übernehmen, bei Abschluss, die geeignetste, die beste, die sinnvollste Wahl für dich dargestellt haben.'
    },
    {
        type: 'textTitl',
        text: '3.5 Unser Leistungsangebot an Versicherungs- und Energiedienstleistung stellt zu keiner Zeit und in keiner Form eine Anlageberatung oder eine Rechtsberatung für dich dar. Bindend für dich ist immer der Wortlaut des Versicherungsscheines oder Energievertrages. Du bist dazu verpflichtet, den genauen Inhalt des Vertrages zu prüfen und mögliche Abweichungen umgehend zu melden.'
    },
    {
        type: 'textTitl',
        text: '3.6 Die Nutzung der von uns angebotenen Leistungen geschieht in jedem Fall auf eigenes Risiko. Unsere Haftung ist im Übrigen auf Vorsatz und grobe Fahrlässigkeit beschränkt. Davon ausgenommen ist die Haftung für Schäden an Körper, Leben und Gesundheit, für die die gesetzlichen Haftungsregeln uneingeschränkt gelten.'
    },
    {
        type: 'textTitl',
        text: '3.7 Wir erfüllen alle  Verpflichtungen mit der Sorgfalt eines ordentlichen Kaufmanns. Die Haftung für die Verletzung beruflicher Sorgfaltspflichten ist auf die in der Verordnung über die Versicherungsvermittlung und -beratung (VersVermV) angegebenen Mindestversicherungssummen beschränkt, es sei denn, wir haben eine Kardinalspflicht oder eine Nebenpflicht vorsätzlich oder grob fahrlässig verletzt.'
    },
    {
        type: 'textTitl',
        text: '3.8 Wir haften nicht für die Beratungsfehler, Vergütung und Haftungsbegrenzung des Vorvermittlers, die durch bestehende gesetzliche Obliegenheiten nach Eintritt des Schadensfalls eintreten werden, § 86 Absatz 2 VVG.'
    },
    {
        type: 'subTitle',
        text: '4.  Bedingungen'
    },
    {
        type: 'textTitl',
        text: '4.1 Die Eingabe und Übermittlung deiner persönlichen Daten erfolgt online über die von uns vorbereiteten Eingabemasken oder über andere ausdrücklich von uns im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher von dir in die Eingabemaske eingegebenen oder sonst übermittelten Daten bist ausschließlich du selbst verantwortlich.'
    },
    {
        type: 'textTitl',
        text: '4.2 Für den Fall der Übermittlung von vorsätzlich falschen oder unwahren persönlichen Daten durch dich, behalten wir uns vor, dich von der Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Leistungen auszuschließen und Ersatz für dadurch eingetretene Schäden zu verlangen.'
    },
    {
        type: 'textTitl',
        text: '4.3 Wenn Du falsche und/oder unzureichende Angaben als Versicherungsnehmer machst, kann das dazu führen, dass du Deinen Versicherungsschutz verlierst.'
    },
    {
        type: 'textTitl',
        text: '4.4 Wenn wir Zweifel daran haben, dass die Angaben nicht richtig und vollständig sind, können wir die Zusammenarbeit jederzeit beenden und deine Registrierung rückgängig machen. Im Hinblick auf die Verarbeitung von Daten gelten unsere Datenschutzerklärungen. Diese kannst du unter www.mycontracts.app einsehen und herunterladen. Du hast keinen Anspruch auf ein Nutzerkonto; Wir können deinen Zugang übergangsweise sperren, sofern ein berechtigtes Interesse daran besteht. Dabei beachten wir deine und unsere Interessen in gleichen Maßen.'
    },
    {
        type: 'textTitl',
        text: '4.5 Durch die Nutzung unserer Dienste kommt zwischen uns ein Nutzungsvertrag zustande. Die Einzelheiten zur Nutzung sind in diesen AGB geregelt.'
    },
    {
        type: 'textTitl',
        text: '4.6. Wir sind jederzeit berechtigt, die Bereitstellung der Internet-Plattform bzw. App einzustellen oder dein Recht zur Nutzung dieser zu beenden. Dies gilt insbesondere dann, wenn du gegen die Nutzungsbedingungen verstößt.'
    },
    {
        type: 'textTitl',
        text: '4.7. Du bist für das sichere Aufbewahren deiner Zugangsdaten zuständig. Du musst die Zugangsdaten vertraulich behandeln. Das bedeutet, dass du die Daten geheim halten musst. Dritte dürfen diese nicht einsehen. Besteht ein Verdacht auf Missbrauch der Daten, musst du uns dies sofort mitteilen. Bitte schicke uns hierfür eine formlose Email an info@mycontracts.app.'
    },
    {
        type: 'subTitle',
        text: '5. Vertragsimport und Vollmacht'
    },
    {
        type: 'textTitl',
        text: '5.1 Du kannst uns beauftragen, Vertragsdaten und Dokumente vom jeweiligen Anbieter einzuholen. Hierzu ist die vorherige Unterzeichnung eines Maklerauftrages und einer Maklervollmacht (Versicherungsverträge) und/oder des Energiewechselmandats notwendig.'
    },
    {
        type: 'textTitl',
        text: 'Du erteilest uns deine Vollmacht auf zwei Wege:'
    },
    {
        type: 'textTitl',
        text: 'a) Digitale Unterschrift: Wir erfassen deine Unterschrift digital und tragen sie in die Vollmacht ein. Wir können auch andere Verfahren verwenden, um deine Unterschrift zu digitalisieren.'
    },
    {
        type: 'textTitl',
        text: 'b) Eigenhändige Unterschrift: Du unterschreibst die Vollmacht eigenhändig und sendest uns die unterschriebene Vollmacht per Post.'
    },
    {
        type: 'textTitl',
        text: '5.2 Wir speichern deine Unterschrift in unserem System. Wir nutzen deine Unterschrift auch für andere Dokumente, nachdem du der Nutzung zuvor ausdrücklich zugestimmt hast. Wir leiten die unterschriebene Vollmacht - falls erforderlich - an die Anbieter oder andere berechtigte Dritte weiter.'
    },
    {
        type: 'textTitl',
        text: '5.3 Wir haben das Recht, eine Vollmacht jederzeit und ohne Angabe von Gründen abzulehnen.'
    },
    {
        type: 'textTitl',
        text: '5.4 Der Umfang der Vollmacht richtet sich nach deinem jeweiligen Auftrag. Sie entspricht in Ausmaß und Reichweite einer für die jeweilige Branche (Energiebranche oder Versicherungsbranche) üblichen Vollmacht. Die Vollmacht erstreckt sich zum Beispiel auf:'
    },
    {
        type: 'textTitl',
        text: '- Beschaffung deiner Daten,'
    },
    {
        type: 'textTitl',
        text: '- Betreuung und Verwaltung deiner Verträge,'
    },
    {
        type: 'textTitl',
        text: '- Vermittlung von neuen Verträgen,'
    },
    {
        type: 'textTitl',
        text: '- Wechseln bestehender Verträge'
    },
    {
        type: 'textTitl',
        text: '- Unterstützung beim Regulieren von Versicherungsschäden oder bei Problemen im Allgemeinen'
    },
    {
        type: 'textTitl',
        text: '5.5. Du kannst die Vollmacht jederzeit widerrufen. Auch wir haben dieses Recht. Allerdings dürfen wir die Vollmacht in für dich ungünstigen Zeiten nur widerrufen, wenn ein wichtiger Grund dafür vorliegt.'
    },
    {
        type: 'textTitl',
        text: '5.6. Das Inkasso der durch dich geschuldeten Beiträge verbleibt bei den jeweiligen Anbietern.'
    },
    {
        type: 'subTitle',
        text: '6. Vermittlung von Versicherungsverträgen im speziellen'
    },
    {
        type: 'textTitl',
        text: '6.1 Du kannst uns beauftragen, Versicherungsverträge für dich abzuschließen. Dann sind wir verpflichtet, unserem Vorschlag eine hinreichende Anzahl von auf dem Markt angebotenen Verträgen und Versicherern zugrunde zu legen. Darauf aufbauend geben wir dir einen fachlichen Rat, welcher Vertrag geeignet erscheint, deine Bedürfnisse zu erfüllen. Dies gilt nicht, wenn wir im Einzelfall vor Abgabe der Vertragserklärung auf eine begrenzte Auswahl von Unternehmen und Produkten hinweisen.'
    },
    {
        type: 'textTitl',
        text: '6.2 Wenn wir dich auf eine begrenzte Auswahl hinweisen, teilen wir dir mit, auf welcher Markt- und Informationsgrundlage unser Vorschlag erfolgt. Außerdem teilen wir dir die Namen der zugrunde gelegten Unternehmen mit. Auf diese Pflicht kannst du durch eine gesonderte schriftliche Erklärung verzichten.'
    },
    {
        type: 'textTitl',
        text: '6.3 Wir vermitteln dir nur Verträge, die deinen individuellen Bedürfnissen entsprechen. Unsere Vorschläge basieren unter anderem auf diesen Kriterien:'
    },
    {
        type: 'textTitl',
        text: '- dem Verhältnis von Preis und Leistung,'
    },
    {
        type: 'textTitl',
        text: '- der Zuverlässigkeit beim Bearbeiten von Schäden und'
    },
    {
        type: 'textTitl',
        text: '- unserer Expertise.'
    },
    {
        type: 'textTitl',
        text: '6.4 Wir können Lebensversicherungen und Dienstleistungen mit Anlagezweck nur dann vermitteln, wenn du dich identifizierst. Dazu musst du deine Identität durch einen gültigen amtlichen Ausweis nachweisen. Der Ausweis muss ein Lichtbild enthalten und die Pass-und Ausweispflicht erfüllen.'
    },
    {
        type: 'textTitl',
        text: '6.5 Wir vermitteln dir nur Verträge von Unternehmen, die unter Aufsicht der Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) stehen und deren Regelwerk in deutscher Sprache verfasst ist. Auch ausländische Versicherer sind in Deutschland tätig. Die Produkte dieser Unternehmen vermitteln wir nur, wenn deren Angebot und Zuverlässigkeit mit einem deutschen Unternehmen vergleichbar sind. Eine Pflicht hierzu besteht nicht.Einige Unternehmen, sogenannte Direktversicherer, vertreiben ihre Produkte nur selbst. Diese lassen den Abschluss von Verträgen durch Dritte nicht zu. Wir weisen darauf hin, dass wir diese Verträge nicht vermitteln können'
    },
    {
        type: 'textTitl',
        text: 'Unsere Leistungen bzw. Support für Verträge der folgenden Produktarten sind eingeschränkt oder nicht möglich. Diese Produktarten können aber in deinem Vertragsordner erfasst werden:'
    },
    {
        type: 'textTitl',
        text: '- Gesetzliche Krankenkassen'
    },
    {
        type: 'textTitl',
        text: '- Gesetzliche Rentenversicherung'
    },
    {
        type: 'textTitl',
        text: '- Gesetzlichen Unfallversicherung'
    },
    {
        type: 'textTitl',
        text: '6.6 Du musst alle Angaben, die wir für eine vertragsgemäße Beratung und Verwaltung benötigen, wahrheitsgemäß und innerhalb der geforderten Frist übermitteln.'
    },
    {
        type: 'textTitl',
        text: '6.7 Wenn du mitwirken musst, zum Beispiel einen Antrag unterschreiben, musst du alle geforderten Erklärungen auf unsere Aufforderung oder auf Aufforderung des Versicherers ohne Verzug abgeben.'
    },
    {
        type: 'subTitle',
        text: '7. Cashback'
    },
    {
        type: 'textTitl',
        text: '7.1 Wir bieten die Möglichkeit an, Cashback für bestehende und neue Verträge zu erhalten. Grundlage für den Erhalt von Cashback ist eine Vereinbarung von dir mit unserem Cashback- und Shoppingpartner myWorld (im Folgenden "Partner" genannt). Das Cashback wird zu den jeweils geltenden Bestimmungen des Partners gutgeschrieben, ausgezahlt oder als Shoppinggutschein/eVoucher zur Verfügung gestellt.'
    },
    {
        type: 'textTitl',
        text: '7.2 Cashback kann grundsätzlich nur für Produkte gezahlt werden, für die wir von den Anbietern Provisionen erhalten. Teile dieser Provisionen werden, immer dann, wenn diese bei uns auflaufen, an unseren Partner myWorld, für die Bereitstellung seiner Dienstleistung für dich, ausbezahlt.'
    },
    {
        type: 'textTitl',
        text: '7.3 Leider können wir dir, auch auf Grund von ggf. sich verändernden rechtlichen Rahmenbedingungen keine Cashbackzahlungen dauerhaft garantieren. Ein Recht auf Cashbackzahlungen besteht also nicht.'
    },
    {
        type: 'subTitle',
        text: '8. Schlussbestimmungen'
    },
    {
        type: 'textTitl',
        text: '8.1 Sollten einzelne Bestimmungen dieser AGB unwirksam sein bzw. werden, so bleiben die Bedingungen im Übrigen wirksam. Wir werden dann die unwirksame Bestimmung durch eine Wirksame ersetzen.'
    },
    {
        type: 'textTitl',
        text: '8.2 Sämtliche sich aus unserem gegenseitigen Vertragsverhältnis ergebenden Rechte oder Ansprüche sind nicht übertragbar, abtretbar oder belastbar.'
    },
    {
        type: 'textTitl',
        text: '8.3 Wir behalten uns das Recht vor, die AGB einseitig zu ändern, wenn die Gesetzeslage dies erfordert, wir unser Geschäftsmodell anpassen oder es eine neue Sachlage auf dem zugrunde gelegten Markt gibt. Sollten wir die AGB ändern wirst du rechtzeitig und umfassend von uns darüber informiert und hast ebenso mit einer Frist von acht Wochen nach Inkrafttreten das Recht Widerspruch einzulegen. Wenn wir innerhalb dieser Frist keinen Widerspruch von dir erhalten, gelten die geänderten AGB als vereinbart.'
    },
    {
        type: 'textTitl',
        text: '8.4 Für sämtliche Streitigkeiten zwischen dir und uns gilt ausschließlich deutsches Recht.'
    },
    {
        type: 'textTitl',
        text: '8.5 Für alle Streitigkeiten zwischen dir und uns, gleich aus welchem Rechtsgrund, wird als Gerichtsstand der Sitz der deutschen Tochtergesellschaft vereinbart.'
    },
    //info privacy
    {
        type: 'secTitle',
        text: 'Datenschutzrichtlinien'
    },
    {
        type: 'catTitle',
        text: 'Datenschutzerklärung'
    },
    {
        type: 'subTitle',
        text: '1. Einleitung'
    },
    {
        type: 'textTitl',
        text: 'Mit den folgenden Informationen möchten wir Ihnen als "betroffener Person" einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer Internetseiten und Apps (im Folgenden allgemein "Seiten" genannt) ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein. Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder E- Mail - Adresse, erfolgt stets im Einklang mit der Datenschutz - Grundverordnung(DS - GVO) und in Übereinstimmung mit den für die Disbro GmbH geltenden landesspezifischen Datenschutzbestimmungen.Mittels dieser Datenschutzerklärung möchten wir Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.'
    },
    {
        type: 'textTitl',
        text: 'Wir haben als für die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu übermitteln.'
    },
    {
        type: 'subTitle',
        text: '2. Verantwortlicher'
    },
    {
        type: 'textTitl',
        text: 'Verantwortlicher im Sinne der DS-GVO ist die:'
    },
    {
        type: 'textTitl',
        text: 'Disbro GmbH'
    },
    {
        type: 'textTitl',
        text: 'P3, 12-13'
    },
    {
        type: 'textTitl',
        text: '68161 Mannheim'
    },
    {
        type: 'textTitl',
        text: 'Tel.: +49 (0) 621 490 733 0'
    },
    {
        type: 'textTitl',
        text: 'Fax: +49 (0) 621 490 733 20'
    },
    {
        type: 'textTitl',
        text: 'E-Mail: hallo@disbro.de'
    },
    {
        type: 'textTitl',
        text: 'Web: www.disbro.de'
    },
    {
        type: 'textTitl',
        text: 'Geschäftsführer: Florian Grund'
    },
    {
        type: 'subTitle',
        text: '3. Datenschutzbeauftragter'
    },
    {
        type: 'textTitl',
        text: 'Den Datenschutzbeauftragten erreichen Sie wie folgt:'
    },
    {
        type: 'textTitl',
        text: 'PROLIANCE GmbH'
    },
    {
        type: 'textTitl',
        text: 'www.datenschutzexperte.de'
    },
    {
        type: 'textTitl',
        text: 'Leopoldstr. 21'
    },
    {
        type: 'textTitl',
        text: '80802 München'
    },
    {
        type: 'textTitl',
        text: 'datenschutzbeauftragter@datenschutzexperte.de'
    },
    {
        type: 'textTitl',
        text: 'Sie können sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren Datenschutzbeauftragten wenden.'
    },
    {
        type: 'subTitle',
        text: '4. Begriffsbestimmungen'
    },
    {
        type: 'textTitl',
        text: 'Die Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.'
    },
    {
        type: 'textTitl',
        text: 'Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:'
    },
    {
        type: 'subTitle',
        text: '1. Personenbezogene Daten'
    },
    {
        type: 'textTitl',
        text: 'Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.'
    },
    {
        type: 'subTitle',
        text: '2. Betroffene Person'
    },
    {
        type: 'textTitl',
        text: 'Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen (unser Unternehmen) verarbeitet werden.'
    },
    {
        type: 'subTitle',
        text: '3. Verarbeitung'
    },
    {
        type: 'textTitl',
        text: 'Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.'
    },
    {
        type: 'subTitle',
        text: '4. Einschränkung der Verarbeitung'
    },
    {
        type: 'textTitl',
        text: 'Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.'
    },
    {
        type: 'subTitle',
        text: '5. Profiling'
    },
    {
        type: 'textTitl',
        text: 'Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.'
    },
    {
        type: 'subTitle',
        text: '6. Pseudonymisierung'
    },
    {
        type: 'textTitl',
        text: 'Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.'
    },
    {
        type: 'subTitle',
        text: '7. Auftragsverarbeiter'
    },
    {
        type: 'textTitl',
        text: 'Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.'
    },
    {
        type: 'subTitle',
        text: '8. Empfänger'
    },
    {
        type: 'textTitl',
        text: 'Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.'
    },
    {
        type: 'subTitle',
        text: '9. Dritter'
    },
    {
        type: 'textTitl',
        text: 'Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.'
    },
    {
        type: 'subTitle',
        text: '10. Einwilligung'
    },
    {
        type: 'textTitl',
        text: 'Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass Sie mit der Verarbeitung der Sie betreffenden personenbezogenen Daten einverstanden ist.'
    },
    {
        type: 'subTitle',
        text: '10. Einwilligung'
    },
    {
        type: 'catTitle',
        text: '5. Rechtsgrundlage der Verarbeitung'
    },
    {
        type: 'textTitl',
        text: 'Art. 6 Abs. 1 lit. a DS-GVO (i.V.m. § 15 Abs. 3 TMG) dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.'
    },
    {
        type: 'textTitl',
        text: 'Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.'
    },
    {
        type: 'textTitl',
        text: 'Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 Abs. 1 lit. c DS-GVO.'
    },
    {
        type: 'textTitl',
        text: 'In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen.'
    },
    {
        type: 'textTitl',
        text: 'Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn Sie ein Kunde unseres Unternehmens sind (Erwägungsgrund 47 Satz 2 DS-GVO).'
    },
    {
        type: 'textTitl',
        text: 'Innerhalb unseres Unternehmens erhalten diejenigen Mitarbeiter Zugriff auf Ihre Daten, die diese zur Erfüllung unserer vertraglichen und/oder gesetzlichen Pflichten benötigen, also mit der vertraglichen Abwicklung betraut sind. In diesem Zusammenhang können das auch von uns eingesetzte Dienstleister und Erfüllungsgehilfen sein. Soweit wir Ihre Daten an Empfänger außerhalb unseres Unternehmens weitergeben, erfolgt dies ausschließlich zur Erfüllung unserer Vertragspflichten im Rahmen unseres Geschäftszweckes, aufgrund gesetzlicher Bestimmungen oder Ihrer ausdrücklich erteilten Einwilligung.'
    },
    {
        type: 'textTitl',
        text: 'Zur Erfüllung unserer Vertragspflichten arbeiten wir u.a. mit folgenden Stellen zusammen:'
    },
    {
        type: 'textTitl',
        text: '&#8226;Kooperationspartner'
    },
    {
        type: 'textTitl',
        text: '&#8226;Lieferanten'
    },
    {
        type: 'textTitl',
        text: '&#8226;Technische und sonstige Dienstleister, die für uns tätig sind'
    },
    {
        type: 'textTitl',
        text: '&#8226;Rechtsanwälte, Steuerberater, Wirtschaftsprüfer'
    },
    {
        type: 'textTitl',
        text: 'Die Disbro GmbH vermittelt keine direkten Finanzprodukte und fungiert als anbieterunabhängige Beratungs- und Supportstelle. Aus diesem Grund arbeitet die Disbro GmbH mit verschiedenen Kooperationspartnern zusammen und Sie erklären sich mit der Annahme unserer Datenschutzbestimmungen damit einverstanden, dass wir Ihre personenbezogenen Daten insbesondere an folgende Unternehmen weitergeben können:'
    },
    {
        type: 'textTitl',
        text: 'Fonds Finanz Maklerservice GmbH, Riesstr. 25, 80992 München'
    },
    {
        type: 'textTitl',
        text: 'Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen'
    },
    {
        type: 'textTitl',
        text: 'Jung, DMS & Cie. AG, Widenmayerstraße 36, 80538 München'
    },
    {
        type: 'catTitle',
        text: '6. Technik'
    },
    {
        type: 'subTitle',
        text: '6.1 SSL/TLS-Verschlüsselung'
    },
    {
        type: 'textTitl',
        text: 'Diese Seite nutzt zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als Betreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass in der Adresszeile des Browsers statt einem "http://" ein "https://" steht und an dem Schloss-Symbol in Ihrer Browserzeile.'
    },
    {
        type: 'textTitl',
        text: 'Wir setzen diese Technologie ein, um Ihre übermittelten Daten zu schützen.'
    },
    {
        type: 'subTitle',
        text: '6.2 Datenerfassung beim Besuch der Internetseite'
    },
    {
        type: 'textTitl',
        text: 'Bei der lediglich informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (in sog. "Server-Logfiles"). Unsere Internetseite erfasst mit jedem Aufruf einer Seite durch Sie oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die'
    },
    {
        type: 'textTitl',
        text: '1. verwendeten Browsertypen und Versionen,'
    },
    {
        type: 'textTitl',
        text: '2. das vom zugreifenden System verwendete Betriebssystem,'
    },
    {
        type: 'textTitl',
        text: '3. die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt(sogenannte Referrer),'
    },
    {
        type: 'textTitl',
        text: '4. die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden,'
    },
    {
        type: 'textTitl',
        text: '5. das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,'
    },
    {
        type: 'textTitl',
        text: '6. eine gekürzte Internet - Protokoll - Adresse(anonymisierte IP - Adresse),'
    },
    {
        type: 'textTitl',
        text: '7. der Internet - Service - Provider des zugreifenden Systems.'
    },
    {
        type: 'textTitl',
        text: 'Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Ihre Person.Diese Informationen werden vielmehr benötigt, um'
    },
    {
        type: 'textTitl',
        text: '1. die Inhalte unserer Internetseite korrekt auszuliefern,'
    },
    {
        type: 'textTitl',
        text: '2. die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,'
    },
    {
        type: 'textTitl',
        text: '3. die dauerhafte Funktionsfähigkeit unserer IT - Systeme und der Technik unserer Internetseite zu gewährleisten sowie'
    },
    {
        type: 'textTitl',
        text: '4. um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen.'
    },
    {
        type: 'textTitl',
        text: 'Diese erhobenen Daten und Informationen werden durch uns daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen.Die anonymen Daten der Server - Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit.f DS - GVO.Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.'
    },
    {
        type: 'catTitle',
        text: '7. Cookies'
    },
    {
        type: 'subTitle',
        text: '7.1 Allgemeines zu Cookies'
    },
    {
        type: 'textTitl',
        text: 'Wir setzen auf unserer Internetseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem IT-System (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen.'
    },
    {
        type: 'textTitl',
        text: 'In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.'
    },
    {
        type: 'textTitl',
        text: 'Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.'
    },
    {
        type: 'textTitl',
        text: 'Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen Sie getätigt haben, um diese nicht noch einmal eingeben zu müssen. Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu'
    },
    {
        type: 'textTitl',
        text: 'erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.'
    },
    {
        type: 'subTitle',
        text: '7.2 Rechtsgrundlage für die Verwendung von Cookies'
    },
    {
        type: 'textTitl',
        text: 'Die durch Cookies verarbeiteten Daten, die für die ordnungsmäßige Funktion der Webseite benötigt werden, sind damit zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO erforderlich.'
    },
    {
        type: 'textTitl',
        text: 'Für alle anderen Cookies gilt, dass Sie über unser Opt-in Cookie-Banner Ihre Einwilligung hierzu im Sinne von Art. 6 Abs. 1 lit. a DS-GVO abgegeben haben.'
    },
    {
        type: 'catTitle',
        text: '8. Inhalte unserer Internetseite'
    },
    {
        type: 'subTitle',
        text: '8.1 Kontaktaufnahme / Kontaktformular'
    },
    {
        type: 'textTitl',
        text: 'Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit.f DS- GVO.Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit.b DS - GVO.Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.'
    },
    {
        type: 'textTitl',
        text: 'Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DS-GVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DS-GVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.'
    },
    {
        type: 'subTitle',
        text: '8.2 Bewerbungsmanagement / Stellenbörse'
    },
    {
        type: 'textTitl',
        text: 'Wir erheben und verarbeiten die personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise per E-Mail oder über ein auf der Website befindliches Webformular, an uns übermittelt. Schließen wir einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Wird von uns kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen unsererseits entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).'
    },
    {
        type: 'textTitl',
        text: 'Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist Art. 88 DS-GVO i.V.m. § 26 Abs. 1 BDSG.'
    },
    {
        type: 'catTitle',
        text: '9. Plugins und andere Dienste'
    },
    {
        type: 'subTitle',
        text: '9.1 Google Maps'
    },
    {
        type: 'textTitl',
        text: 'Auf unserer Website verwenden wir Google Maps (API) von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Maps ist ein Webdienst zur Darstellung von interaktiven (Land-)Karten, um geographische Informationen visuell darzustellen. Über die Nutzung dieses Dienstes kann Ihnen beispielsweise unser Standort angezeigt und eine etwaige Anfahrt erleichtert werden.'
    },
    {
        type: 'textTitl',
        text: 'Bereits beim Aufrufen derjenigen Unterseiten, in die die Karte von Google Maps eingebunden ist, werden Informationen über Ihre Nutzung unserer Website (wie z.B. Ihre IP-Adresse) an Server von Google in den USA übertragen und dort gespeichert. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich aus Ihrem Google-Benutzerkonto ausloggen. Google speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an Google richten müssen.'
    },
    {
        type: 'textTitl',
        text: 'Wenn Sie mit der künftigen Übermittlung Ihrer Daten an Google im Rahmen der Nutzung von Google Maps nicht einverstanden sind, besteht auch die Möglichkeit, den Webdienst von Google Maps vollständig zu deaktivieren, indem Sie die Anwendung JavaScript in Ihrem Browser ausschalten. Google Maps und damit auch die Kartenanzeige auf dieser Internetseite kann dann nicht genutzt werden.'
    },
    {
        type: 'textTitl',
        text: 'Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO.'
    },
    {
        type: 'textTitl',
        text: 'Die Nutzungsbedingungen von Google können Sie unter https://www.google.de/intl/de/policies/terms/regional.html einsehen, die zusätzlichen'
    },
    {
        type: 'textTitl',
        text: 'Nutzungsbedingungen für Google Maps finden Sie unter https://www.google.com/intl/de_US/help/terms_maps.html'
    },
    {
        type: 'subTitle',
        text: '9.2 Google Fonts'
    },
    {
        type: 'textTitl',
        text: 'Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wir setzen die sogenannte "Offline" Variante ein, daher baut Ihr Browser keine Verbindung zu Google auf, um die Fonts zu laden. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.'
    },
    {
        type: 'catTitle',
        text: '10. Datenverarbeitung Kunden & Lieferanten'
    },
    {
        type: 'textTitl',
        text: 'Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wir setzen die sogenannte "Offline" Variante ein, daher baut Ihr Browser keine Verbindung zu Google auf, um die Fonts zu laden. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.'
    },
    {
        type: 'subTitle',
        text: '10.1 Auftragsabwicklung'
    },
    {
        type: 'textTitl',
        text: 'Kontaktdaten von Ansprechpartnern des Kunden/Lieferanten werden zur Auftragsabwicklung erfasst und verarbeitet. Bei Aufträgen mit Geschäftskunden (B2B) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. f DSGVO. Bei Aufträgen mit Privatkunden (B2C) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. c DSGVO.'
    },
    {
        type: 'subTitle',
        text: '10.2 Beschaffung und Einkauf'
    },
    {
        type: 'textTitl',
        text: 'Kontaktdaten von Ansprechpartnern (Lieferanten) werden zur Organisation und Durchführung von Einkauf und Beschaffung verarbeitet. Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 lit. f DSGVO.'
    },
    {
        type: 'subTitle',
        text: '10.3 Dokumentmanagementsystem (DMS)'
    },
    {
        type: 'textTitl',
        text: 'Wir erheben personenbezogene Daten von Kunden/Lieferanten zum Zwecke des Betriebs eines Dokumentenmanagementsystem (DMS) zur revisionssicheren Ablage von Dokumenten aller Art und Nachvollziehbarkeit deren Erstellung, Veränderung und Löschung. Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO erforderlich.'
    },
    {
        type: 'subTitle',
        text: '10.4 Kontaktdatenverwaltung'
    },
    {
        type: 'textTitl',
        text: 'Kontaktdaten von Kunden und Lieferanten werden zur besseren Übersicht und Verfügbarkeit in das CRM-/ERP-System eingebracht und verwaltet. Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 lit. f DSGVO.'
    },
    {
        type: 'subTitle',
        text: '10.5 Mahnwesen'
    },
    {
        type: 'textTitl',
        text: 'Wir erheben und bearbeiten die personenbezogenen Daten von Kunden zum Zwecke der Durchführung von Mahnungen. Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 lit. f DSGVO.'
    },
    {
        type: 'subTitle',
        text: '10.6 Rechnungswesen'
    },
    {
        type: 'textTitl',
        text: 'Wir erheben und verarbeiten die personenbezogenen Daten von Kunden zum Zwecke der Rechnungserstellung. Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist die Erfüllung einer rechtlichen Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.'
    },
    {
        type: 'subTitle',
        text: '10.7 Zahlungsverkehr / Onlinebanking'
    },
    {
        type: 'textTitl',
        text: 'Wir erheben und verarbeiten die personenbezogenen Daten von Kunden/Lieferanten zum Zwecke der Abwicklung des Zahlungsverkehrs inkl. Onlinebanking. Die Rechtsgrundlage der Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 lit. f DSGVO.'
    },
    {
        type: 'catTitl',
        text: '11. Ihre Rechte als betroffene Person'
    },
    {
        type: 'subTitl',
        text: '11.1 Recht auf Bestätigung'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.'
    },
    {
        type: 'subTitl',
        text: '11.2 Recht auf Auskunft Art. 15 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.'
    },
    {
        type: 'subTitl',
        text: '11.3 Recht auf Berichtigung Art. 16 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.'
    },
    {
        type: 'subTitl',
        text: '11.4 Löschung Art. 17 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw. Speicherung nicht erforderlich ist.'
    },
    {
        type: 'subTitl',
        text: '11.5 Einschränkung der Verarbeitung Art. 18 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen gegeben ist.'
    },
    {
        type: 'subTitl',
        text: '11.6 Datenübertragbarkeit Art. 20 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde.'
    },
    {
        type: 'textTitl',
        text: 'Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.'
    },
    {
        type: 'subTitl',
        text: '11.7 Widerspruch Art. 21 DS-GVO'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit.e(Datenverarbeitung im öffentlichen Interesse) oder f(Datenverarbeitung auf Grundlage einer Interessenabwägung) DS - GVO erfolgt, Widerspruch einzulegen.'
    },
    {
        type: 'textTitl',
        text: 'Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling im Sinne von Art. 4 Nr. 4 DS - GVO.'
    },
    {
        type: 'textTitl',
        text: 'Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende berechtigte Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.'
    },
    {
        type: 'textTitl',
        text: 'In Einzelfällen verarbeiten wir personenbezogene Daten, um Direktwerbung zu betreiben.Sie können jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einlegen.Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.Widersprechen Sie gegenüber uns der Verarbeitung für Zwecke der Direktwerbung, so werden wir die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.'
    },
    {
        type: 'textTitl',
        text: 'Zudem haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS - GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.'
    },
    {
        type: 'textTitl',
        text: 'Ihnen steht es frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002 / 58 / EG, Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.'
    },
    {
        type: 'subTitl',
        text: '11.8 Widerruf einer datenschutzrechtlichen Einwilligung'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.'
    },
    {
        type: 'subTitl',
        text: '11.9 Beschwerde bei einer Aufsichtsbehörde'
    },
    {
        type: 'textTitl',
        text: 'Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu beschweren.'
    },
    {
        type: 'catTitl',
        text: '12. Routinemäßige Speicherung, Löschung und Sperrung von personenbezogenen Daten'
    },
    {
        type: 'textTitl',
        text: 'Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen unterliegt, vorgesehen wurde.'
    },
    {
        type: 'textTitl',
        text: 'Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.'
    },
    {
        type: 'catTitl',
        text: '13. Aktualität und Änderung der Datenschutzerklärung'
    },
    {
        type: 'textTitl',
        text: 'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand: April 2022.'
    },
    {
        type: 'textTitl',
        text: 'Durch die Weiterentwicklung unserer Internetseiten und Angebote oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Sie finden hier an dieser Stelle unserer Website die jeweils aktuelle Fassung, die Sie jederzeit abrufen und ausdrucken können.'
    },
    //info advisement
    {
        type: 'secTitle',
        text: 'Erstinformation'
    },
    {
        type: 'subTitl',
        text: 'Name und Anschrift des Maklers:'
    },
    {
        type: 'textTitl',
        text: 'Disbro GmbH'
    },
    {
        type: 'textTitl',
        text: 'gesetzlich vertreten durch den Geschäftsführer Florian Grund'
    },
    {
        type: 'textTitl',
        text: 'P3, 12-13'
    },
    {
        type: 'textTitl',
        text: '68161 Mannheim'
    },
    {
        type: 'subTitl',
        text: 'Aufsichtsbehörde für die Erlaubnis nach § 34d GewO:'
    },
    {
        type: 'textTitl',
        text: 'Industrie- und Handelskammer Rhein Neckar, '
    },
    {
        type: 'textTitl',
        text: 'L 1, 2, '
    },
    {
        type: 'textTitl',
        text: '68161 Mannheim'
    },
    {
        type: 'textTitl',
        text: '+49 (0) 621 17090'
    },
    {
        type: 'textTitl',
        text: 'www.rhein-neckar.ihk24.de '
    },
    {
        type: 'subTitl',
        text: 'Tätigkeitsart:'
    },
    {
        type: 'textTitl',
        text: 'Versicherungsmakler mit einer Erlaubnispflicht gemäß § 34d Abs. 1 Nr. 2 GewO (Gewerbeordnung) in Verbindung mit § 11a GewO (Gewerbeordnung).'
    },
    {
        type: 'subTitl',
        text: 'Gemeinsame Registerstelle:'
    },
    {
        type: 'textTitl',
        text: 'Deutscher Industrie- und Handelskammertag (DIHK) e.V., Breite Straße 29, 10178 Berlin Telefon: +49(180) 500585-0(14 Cent aus dem dt.Festnetz, abweichende Preise aus dem Mobilfunknetz) Registerabruf: www.vermittlerregister.info Registrierungsnummer als Versicherungsmakler: D - 9SA6- C5PXJ - 56'
    },
    {
        type: 'subTitl',
        text: 'Beratung und Vergütung:'
    },
    {
        type: 'textTitl',
        text: 'Der Makler bietet im Zuge der Vermittlung eine Beratung gemäß den gesetzlichen Vorgaben an. Der Makler erhält für die erfolgreiche Vermittlung eines Vermittlungsvertrages eine Courtage vom jeweiligen Produktanbieter. Vermittelt Ihnen der Makler ein Versicherungsprodukt, für welches der Makler vom jeweiligen Produktgeber keine oder keine kostendeckende Vergütung in die Prämie einkalkuliert wurde, wird der Makler Sie vor Beginn unserer Tätigkeit darüber informieren und Ihnen das Angebot zum Abschluss einer Vergütungsvereinbarung unterbreiten.'
    },
    {
        type: 'subTitl',
        text: 'Offenlegung direkter oder indirekter Beteiligungen über 10% an Versicherungsunternehmen oder von Versicherungsunternehmen am Kapital des Versicherungsvermittlers über 10%: '
    },
    {
        type: 'textTitl',
        text: 'Der Makler besitzt weder direkte noch indirekte Beteiligungen von über 10% an den Stimmrechten oder am Kapital eines Versicherungsunternehmens noch besitzen Versicherungsunternehmen oder Muttergesellschaften von Versicherungsunternehmen eine direkte oder indirekte Beteiligung von über 10% an den Stimmrechten oder am Kapital des Maklers.'
    },
    {
        type: 'subTitl',
        text: 'Anschriften der Schlichtungsstellen:'
    },
    {
        type: 'textTitl',
        text: 'Versicherungsombudsmann e.V.: Postfach 080632, 10006 Berlin (weitere Informationen unter www.versicherungsombudsmann.de) Ombudsmann für die private Kranken- und Pflegeversicherung: Postfach 060222, 10052 Berlin(weitere Informationen unter www.pkv - ombudsmann.de)'
    },
    {
        type: 'textTitl',
        text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin): Postfach 1253, 53002 Bonn (weitere Informationen unter www.bafin.de unter dem Stichwort Ombudsleute)'
    },
    {
        type: 'subTitl',
        text: 'Berufsrechtliche Regelungen:'
    },
    {
        type: 'textTitl',
        text: '§ 34 d Gewerbeordnung'
    },
    {
        type: 'textTitl',
        text: '§§ 59 - 68 Versicherungsvertragsgesetz(VVG)'
    },
    {
        type: 'textTitl',
        text: 'Versicherungsvermittlerverordnung(VersVermV)'
    },
];

export default terms;
