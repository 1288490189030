/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractEdit
 * path: /onboarding/vertrag/daten
 *
 * this is a detail contract page for edit number and add documents into contract in onboarding process
 */

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import ProviderField from '../../components/elements/ProviderField';
import InputText from '../../components/forms/InputText';
import FileDropzone from '../../components/forms/FileDropzone';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/V2/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/onboarding.scss';
import '../../styles/V1/app.scss';

const ContractEditPage = observer(({ DataStore, ContractsStore }) => {
    //get contractTemp
    const { contractTemp } = ContractsStore;

    //state variables
    const [number, setNumber] = useState(contractTemp.numberValue);
    const [documents, setDocuments] = useState(contractTemp.getNewDocuments);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //handling changing documetns array
    const onUpdatedDocuments = (files) => {
        setDocuments(files);
    };

    //handling next button click
    const next = () => {
        // set number of temp contract
        ContractsStore.setFieldForAddingContract({ number });
        // set documents of temp contract
        ContractsStore.addDocumentsForAddingContract(documents);
        // add temp contract into array of user's contracts
        ContractsStore.addTempContract();
        // navigate to contract list page
        navigate(pageUrl.onboardingContractList, { state: { isContractAdded: true, isFinapi: !contractTemp.isManual } });
    };

    return (
        <div className="main-wrapper no-background onboarding-contract-edit-page">
            <Header backButton noMenu title="Dokumente des Vertrags" />
            <div className="main-content-new">
                <h2>{DataStore.getProductTypeTitle(contractTemp.productTypeValue)}</h2>
                <ProviderField title={DataStore.getProviderTitle(contractTemp.providerWithCustomValue)} onClick={() => navigate(pageUrl.onboardingProviders)} />
                <div className="line" />
                <InputText title={trans.t('onboardingContractEditPage', 'number')} defaultValue={contractTemp.numberValue} onChange={(val) => setNumber(val)} />
                <FileDropzone documents={documents} onChange={onUpdatedDocuments} />
            </div>
            <BottomLinks />
            {contractTemp.providerWithCustomValue && (
                <Bottom>
                    <Button
                        role="btnNext"
                        title={trans.t('onboardingUserDataPage', 'btnNext')}
                        onClick={next}
                        green
                    />
                </Bottom>
            )}
        </div>
    );
});

export default ContractEditPage;
