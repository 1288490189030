/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingTerms
 * path: /onboarding/terms
 *
 * this is a terms confirmation page to start onboarding
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/HeaderV2';
import Checkbox from '../../components/forms/Checkbox';
import ButtonLink from '../../components/buttons/ButtonLink';
import Button from '../../components/V2/Button';
import PopupTerms from '../../components/popups/PopupTerms';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/app.scss';
import '../../styles/V1/terms.scss';
import termsBoard from '../../assets/V2/termsBoard.svg';

const TermsPage = observer(({ UserStore }) => {
    //state variables
    const [isChecked, setIsChecked] = useState(true);
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [infoChecked, setInfoChecked] = useState(false);

    //ref variable for popup
    const popupTermsRef = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //function that handles a 'Next' button click
    const next = () => {
        const isValidated = termsChecked && privacyChecked && infoChecked;
        if (!isValidated) {
            setIsChecked(isValidated);
            window.scrollTo({ behavior: 'smooth', top: 200 });
            return null;
        }

        //set terms agree date
        UserStore.setTermsAgreeDate();
        navigate(UserStore.isCompany ? pageUrl.onboardingContractList : pageUrl.onboardingContractAdd);
        return true;
    };

    return (
        <div className="main-wrapper terms-page no-background">
            <Header backButton noMenu backFunc={() => navigate(pageUrl.authSigninPage)} />
            <div className="main-content-new terms-content no-scroll">
                <div className="terms-title">{trans.t('termsPage', 'stepTitle')}</div>
                <div className="terms-points-wrapper">
                    <img src={termsBoard} alt="terms board" className="terms-board" />
                    <div className="terms-points">
                        <div className="terms-point">{trans.t('termsPage', 'step1')}</div>
                        <div className="terms-point">{trans.t('termsPage', 'step2')}</div>
                        <div className="terms-point">{trans.t('termsPage', 'step3')}</div>
                        <div className="terms-point">{trans.t('termsPage', 'step4')}</div>
                        <div className="terms-point">{trans.t('termsPage', 'step5')}</div>
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <div className="conditions">
                    <div className="conditions-wrapper">
                        <div>
                            <Checkbox role="checkboxTerms" onClick={(val) => setTermsChecked(val)} className={!termsChecked && !isChecked ? 'red' : ''}>
                                <div className="condition-title">
                                    {trans.t('termsPage', 'checkboxText1')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink1')}
                                        onClick={() => popupTermsRef.current.show()}
                                        className={!termsChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                            <Checkbox role="checkboxPrivacy" onClick={(val) => setPrivacyChecked(val)} className={!privacyChecked && !isChecked ? 'red' : ''}>
                                <div className="condition-title">
                                    {trans.t('termsPage', 'checkboxText2')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink2')}
                                        onClick={() => popupTermsRef.current.show('privacy')}
                                        className={!privacyChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                            <Checkbox role="checkboxInfo" onClick={(val) => setInfoChecked(val)} className={!infoChecked && !isChecked ? 'red' : ''}>
                                <div className="condition-title">
                                    {trans.t('termsPage', 'checkboxText2')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink3')}
                                        onClick={() => popupTermsRef.current.show('info')}
                                        className={!infoChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                        </div>
                    </div>
                    <Button
                        title={trans.t('termsPage', 'btnNext')}
                        role="btnNext"
                        big
                        green
                        onClick={next}
                    />
                </div>
            </div>
            <PopupTerms ref={popupTermsRef} />
        </div>
    );
});

export default TermsPage;
