/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingProviders
 * path: /onboarding/vertrag/anbieter
 *
 * this is a page show providers list to select product
 */

import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/V2/Button';
import SearchInput from '../../components/elements/SearchInput';
import InputText from '../../components/forms/InputText';
import CheckboxList from '../../components/forms/CheckboxList';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/onboarding.scss';
import IconExclamation from '../../assets/icon-exclamation-red.png';
import '../../styles/V1/app.scss';
import Tooltip from '../../components/V2/Tooltip';

const ProvidersPage = observer(({ DataStore, ContractsStore }) => {
    //get contract that will be added/updated from store
    const { contractTemp } = ContractsStore;

    //state variables
    const [provider, setProvider] = useState(contractTemp.providerValue);
    const [customProvider, setCustomProvider] = useState(contractTemp.customProviderValue);
    const [loaded, setLoaded] = useState(false);
    const [providers, setProviders] = useState([]);
    const [isProviderNotRelated, setIsProviderNotRelated] = useState(false);

    //ref variable for popup
    const refInput = useRef();
    const refChecklist = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //for getting prop 'contractId' from another screen
    const { state } = useLocation();

    //component did mount handler
    useEffect(() => {
        if (refAlert && isProviderNotRelated) {
            refAlert.current.error(trans.t('onboardingProvidersPage', 'providerIsNotRelated'));
        }
    }, [isProviderNotRelated, setIsProviderNotRelated]);

    //get edit contract to get edit provider
    const contractId = !!state && state.hasOwnProperty('contractId') && state.contractId;
    const editContract = ContractsStore.getContract(contractId);

    //load providers from store
    const loadProviders = (val) => {
        const product = DataStore.getProduct(contractTemp.productTypeValue);
        const { categoriesValue } = product;
        let arr = DataStore.getProvidersListByFilter(categoriesValue, val);
        if (!arr.length) {
            // 26667 - this is a category for Private Insurance. We show this category if there are no providers in curent categories
            arr = DataStore.getProvidersListByFilter(['26667'], val);
        }
        setProviders(arr);
        return arr;
    };

    //if we edit we need to have variable for selected provider - is it related or no
    let isNotRelated = false;

    if (!loaded) {
        const arrProviders = loadProviders('');

        isNotRelated = contractId && !editContract.customProviderValue && !!editContract.providerValue && arrProviders.findIndex((obj) => obj.id === editContract.providerValue) === -1;

        if (contractId && editContract.customProviderValue) {
            setCustomProvider(editContract.customProviderValue);
        }
        if (!isNotRelated) {
            setProvider(editContract.providerValue);
        } else {
            setIsProviderNotRelated(true);
        }

        setLoaded(true);
    }

    //handling when customer click on checkbox of any provider
    const checkingProvider = (val) => {
        setCustomProvider('');
        refInput.current.setValue('');
        setProvider(val);
    };

    //handling when customer enter custom provider
    const addCustomProvider = (val) => {
        setProvider('');
        setCustomProvider(val);
        if (refChecklist.current) {
            refChecklist.current.unselectAll();
        }
    };

    //handling next button click
    const next = () => {
        const isBroker = DataStore.getIsPossibleBroker(contractTemp.productTypeValue, provider);
        ContractsStore.setProviderForAddingContract({ provider, customProvider, isBroker });
        navigate(pageUrl.onboardingContractEdit);
    };

    return (
        <div className="main-wrapper no-background onboarding-contract-list-page">
            <Header backButton noMenu title="Anbieter auswählen" />
            <div style={{ width: '100%', padding: '0 20px' }}>
                <Tooltip position="top">
                    {trans.t('onboardingProvidersPage', 'productTitle')} {DataStore.getProductTypeTitle(contractTemp.productTypeValue)}
                </Tooltip>
                <SearchInput onChange={(val) => loadProviders(val)} black />
            </div>
            <div className="main-content-new">
                {!!contractId && (
                    <div className="wrapper-big">
                        <div className="selected-item">
                            <div className="wrapper">
                                {isNotRelated && <img src={IconExclamation} alt="" />}
                                <span>
                                    {trans.t('onboardingProvidersPage', 'selectedProviderText')}:{' '}
                                    <span className="text-orange">{DataStore.getProviderTitle(editContract.providerWithCustomValue)}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {!providers.length && trans.t('onboardingProvidersPage', 'noProviders')}
                {!!providers.length && <CheckboxList ref={refChecklist} list={providers} onClick={checkingProvider} defaultValue={provider} />}
                <div className="custom-provider">
                    <InputText
                        ref={refInput}
                        title={trans.t('onboardingProvidersPage', 'customProvider')}
                        defaultValue={customProvider}
                        onChange={addCustomProvider}
                        role="customInputTestId"
                    />
                </div>
            </div>
            {(!!provider || !!customProvider) && (
                <Bottom>
                    <Button
                        role="btnNext"
                        title={trans.t('onboardingUserDataPage', 'btnNext')}
                        onClick={next}
                        green
                    />
                </Bottom>
            )}
            <BottomLinks />
            <Alert ref={refAlert} />
        </div>
    );
});

export default ProvidersPage;
