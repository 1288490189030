import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoFull from '../../assets/logoFull.svg';
import Button from '../../components/V2/Button';
import pageUrl from '../../const/pages';
import walletIcon from '../../assets/V2/wallet.svg';
import peopleIcon from '../../assets/V2/people.png';
import supportIcon from '../../assets/V2/support.png';
import savingsIcon from '../../assets/V2/savings.png';
import partIcon from '../../assets/V2/part.png';

const BenefitsPage = () => {
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            icon: supportIcon,
            title: 'Genieße die Ordnung und den Service',
            description: 'Den Überblick über alle Verträge zu behalten ist oft nicht so einfach. Mit Conunity organisieren wir dir deine Verträge digital und du hast deine Vertragsdetails immer aktuell und griffbereit.',
            additionalText: 'Außerdem stehen dir 24/7 persönliche Ansprechpartner zur Verfügung, die sich um deine Anliegen kümmern. So erhältst du im Schadenfall schneller und mehr Geld und sparst dir Zeit, Geld und Nerven – kostenlos und unkompliziert!',
        },
        {
            icon: savingsIcon,
            title: 'Optimiere deine Verträge',
            // eslint-disable-next-line max-len
            description: 'Nutze das Know-how unserer Experten und lasse deine bestehenden Verträge prüfen. Wir empfehlen dir unverbindlich ein Vergleichsangebot mit garantiert besserem Preis-Leistungsverhältnis. Wir vergleichen dafür über 300 Versicherungs- und Energiegesellschaften und beraten zu 100% unabhängig und transparent. So sicherst du dir die besten Verträge immer zu den besten Konditionen, sparst Geld und das ohne zusätzliche Gebühren oder versteckte Kosten.',
        },
        {
            icon: walletIcon,
            title: 'Erhalte Cashback und Empfehlungsprovisionen',
            description: 'Als Mitglied von Conunity profitierst du nicht nur von kostenlosem Service und professioneller Unterstützung durch unsere Experten, du bekommst auch Geld zurück – und das sogar auf bereits bestehende Verträge!',
            additionalText: 'Mehr noch. Empfehle Conunity an deine Freunde und Bekannte weiter oder teile Conunity über soziale Medien und erhalte so dauerhafte Empfehlungsprovisionen für jeden durch dich gewonnenen User!',
        },
        {
            icon: peopleIcon,
            title: 'Werde Businesspartner',
            // eslint-disable-next-line max-len
            description: 'Du bist Networker, Influencer oder möchtest es werden? Als Businesspartner kannst du dir mit Conunity schnell und einfach ein professionelles Business mit hohen, laufenden Affiliate-Provisionen aufbauen. Du profitierst an allen Umsätzen, die durch deine direkten Empfehlungen entstehen doppelt! Aber nicht nur das. Du baust dir ein dauerhaftes Multi-Level-Affiliate Netzwerk auf und profitierst auch von Umsätzen von den Empfehlungen deiner Empfehlungen u.s.w.. So verdienst du automatisch und dauerhaft Geld.',
        },
        {
            icon: partIcon,
            title: 'Werde Shareholder',
            // eslint-disable-next-line max-len
            description: 'Als Kunde bekommst du dauerhaft Cashback und als Empfehlungsgeber oder Businesspartner dauerhafte Affiliate-Provisionen in Form von Token. Selbstverständlich kannst du dir deine Token jederzeit in EUR auf dein Konto auszahlen lassen. Um deinen Ertrag noch einmal zu maximieren, kannst du diese aber auch in Unternehmenstoken tauschen und so dauerhaft am Wachstum und Ertrag von Conunity profitieren. Du sorgst dafür, dass Conunity immer weiter wächst und je größer Conunity wird, desto mehr Wert werden deine Token. Du bist ein Teil von Conunity und ein Teil von Conunity gehört dir – mehr geht nicht!',
        }
    ];

    const handleNext = () => {
        if (currentSlide === slides.length - 1) {
            navigate(pageUrl.onboardingTerms);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    return (
        <div className="main-wrapper benefits-page no-background">
            <div className="main-content-new benefits-content no-scroll">
                <div className="close-button" onClick={() => navigate(pageUrl.onboardingTerms)}>×</div>
                <img src={logoFull} alt="logo" className="logo-full" />

                <div className="benefits-carousel">
                    <div className="benefit-slide">
                        <div className="icon-placeholder">
                            <img src={slides[currentSlide].icon} alt="" className="carousel-icon" />
                        </div>
                        <div className="benefit-title">
                            {slides[currentSlide].title}
                        </div>
                        <div className="benefit-description">
                            <div>{slides[currentSlide].description}</div>
                            <div>{slides[currentSlide].additionalText}</div>
                        </div>
                    </div>
                    <div className="carousel-dots">
                        {slides.map((_, index) => (
                            <div
                                key={index}
                                className={`dot ${index === currentSlide ? 'active' : ''}`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </div>
                <Button
                    green
                    title="Weiter"
                    onClick={handleNext}
                    // className="next-button"
                />
            </div>
        </div>
    );
};

export default BenefitsPage;
