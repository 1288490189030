/**
 * Created by Max Gornostaiev on 10/28/21
 *
 * This is a component to show terms, privacy, agreement from formatted file
 */

import React, { PureComponent } from 'react';

class TextsFromFile extends PureComponent {
    renderItem = (item, index) => {
        const paddingClass = this.props.isPadding16 ? 'padding-16' : '';

        switch (item.type) {
            case 'secTitle':
                if (!item.text) {
                    return null;
                }
                return (
                    <div key={index} className={`title-container ${paddingClass}`}>
                        <h2 className="title">{item.text}</h2>
                    </div>
                );
            case 'catTitle':
                if (!item.text) {
                    return null;
                }
                return (
                    <div key={index} className={`category-title-container ${paddingClass}`}>
                        <h3 className="category-title">{item.text}</h3>
                    </div>
                );
            case 'subTitle':
                if (!item.text) {
                    return null;
                }
                return (
                    <div key={index} className={`subcategory-title-container ${paddingClass}`}>
                        <h4 className="subcategory-title">{item.text}</h4>
                    </div>
                );
            case 'subsubTi':
                if (!item.text) {
                    return null;
                }
                return (
                    <div key={index} className={`subsubcategory-title-container ${paddingClass}`}>
                        <h5 className="subcategory-title">{item.text}</h5>
                    </div>
                );
            case 'textTitl':
            case 'textSubT':
                return (
                    <div
                        key={index}
                        className={`${item.type === 'textSubT' ? 'text-sub-container' : 'text-container'} ${paddingClass}`}
                    >
                        <p className="text">{item.text}</p>
                    </div>
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="texts-from-file">
                {this.props.deText.map((item, index) => this.renderItem(item, index))}
            </div>
        );
    }
}

export default TextsFromFile;
