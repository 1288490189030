/**
 * Created by Max Gornostayev on 3/17/22
 *
 * Help library
 */

import countries from '../const/countries';
import config from '../config';

const Utils = {
    /*
     * get list of objects {id: '', title: ''} filtered by 'searchValue' and sort by alphabetically
     */
    getSearchableList: (arr, searchValue) => {
        searchValue = searchValue ? searchValue.toLowerCase().replace('+', '\\+') : '';
        if (searchValue) {
            arr = arr.filter((v) => v.title.toLowerCase().startsWith(searchValue));
        }
        arr.sort((objA, objB) => {
            return objA.title ? objA.title.localeCompare(objB.title, 'de') : false;
        });
        return arr;
    },

    /*
     * get customer options for select element [ {value: '', label: ''}, ...]
     */
    getPhoneCodeList: () => {
        const ret = [];
        config.countries.map((id) => {
            if (countries.hasOwnProperty(id)) {
                ret.push({
                    value: countries[id].code,
                    label: countries[id].title,
                });
            }
        });
        return ret;
    },

    /*
     * make right format of price value
     */
    priceProcess: (price) => {
        if (!price) {
            return price;
        }
        const t = new RegExp(`\\${config.digit.separator1000}`, 'g');
        const d = new RegExp(`\\${config.digit.decimalSeparator}`, 'g');
        price = price.replace(t, '').replace(d, '.');
        return price;
    },

    /*
     * add dots to the string and cut it
     */
    addDotsToString: (str, len) => {
        len = len || 30;
        return str.length > len ? `${str.substr(0, len)}...` : str;
    },

    /*
     * format number
     * n: value of number
     * c: number of digits after dot
     * d: decimal separator
     * t: separator for 1000
     */
    formatNumber(n, c, d, t) {
        if (!c && c !== 0) {
            c = 0;
        }
        d = d === undefined ? config.digit.decimalSeparator : d;
        t = t === undefined ? config.digit.separator1000 : t;
        const s = n < 0 ? '-' : '';
        const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)), 10));
        let j = i.length;
        j = j > 3 ? j % 3 : 0;
        return (
            s +
            (j ? i.substr(0, j) + t : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
            (c
                ? d +
                  Math.abs(n - i)
                      .toFixed(c)
                      .slice(2)
                : '')
        );
    },

    /*
     * format number
     * val: price
     */
    formatPrice(val) {
        return Utils.formatNumber(val, 2, config.digit.decimalSeparator, config.digit.separator1000) + ' ' + config.currency.sign;
    },

    /*
     * get base64 of the file
     */
    getBase64: async (file) => {
        // eslint-disable-next-line
        const reader = new FileReader();
        reader.readAsDataURL(file);

        // eslint-disable-next-line
        return new Promise((reslove, reject) => {
            reader.onload = () => reslove(reader.result);
            reader.onerror = (error) => reject(error);
        });
    },

    /*
     * resize base64 image
     * @var img - new Image()
     * @var maxWidth - int
     * @var maxHeight - int
     */
    resizeImage: (img, maxWidth, maxHeight) => {
        maxWidth = maxWidth || 200;
        maxHeight = maxHeight || 200;

        // Create and initialize two canvases
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const cs = document.createElement('canvas');
        const ccs = cs.getContext('2d');

        // Determine new ratio based on max size
        let ratio = 1;
        if (img.width > maxWidth) {
            ratio = maxWidth / img.width;
        } else {
            ratio = maxHeight / img.height;
        }

        //set size of canvases
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;
        cs.width = maxWidth;
        cs.height = maxHeight;

        //resize image and put into canvas
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

        //determine start position for the centering
        let startX = 0;
        if (canvas.width < maxWidth) {
            startX = (maxWidth - canvas.width) / 2;
        }
        let startY = 0;
        if (canvas.height < maxHeight) {
            startY = (maxHeight - canvas.height) / 2;
        }
        //centered resized image and put into returned canvas
        ccs.drawImage(canvas, startX, startY, canvas.width, canvas.height);

        return cs.toDataURL('image/png');
    },

    /*
     * resize base64 image
     * @var imgBase64 - string
     * @var maxWidth - int
     * @var maxHeight - int
     */
    resizeBase64Image: (imgBase64, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            try {
                // eslint-disable-next-line
                const img = new Image();
                img.onload = () => {
                    const resizedBase64 = Utils.resizeImage(img, maxWidth, maxHeight);
                    resolve(resizedBase64);
                };
                img.src = imgBase64;
            } catch (e) {
                // eslint-disable-next-line
                console.error('These is an error during resizing image: ', e);
                // eslint-disable-next-line prefer-promise-reject-errors
                reject('');
            }
        });
    },
};

export default Utils;
