/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractAdd
 * path: /onboarding/vertrag/import
 *
 * this is a page for selecting how to add contracts - through finApi or manual - in onboarding proccess
 */

import React from 'react';
import Header from '../components/theme/HeaderV2';
import '../styles/V1/app.scss';
import chevronRightBlack from '../assets/V2/chevronRightBlack.svg';

const Button = ({ title, onClick }) => (
    <button className="support-button" onClick={onClick}>
        {title}
        <img src={chevronRightBlack} className="arrow" />
    </button>
);

const SupportPage = () => {
    return (
        <div className="main-wrapper no-background">
            <Header title="Support & Service" noIcon noMenu backButton noSupport />
            <div className="main-content-new contract-add-page">
                <div className="page-title">Experten kontaktieren</div>

                <div className="support-options">
                    <a href="tel:+491772981076">
                        <Button title="Anrufen" onClick={() => {}} />
                    </a>
                    <Button title="Whatsapp" onClick={() => {}} />
                    <a href="mailto:service@myconunity.com">
                        <Button title="E-Mail" onClick={() => {}} />
                    </a>
                </div>

                <div className="page-title">Online-Service</div>

                <div className="support-options">
                    <Button title="Angebot anfordern" onClick={() => {}} />
                    <Button title="Vertrag vergleichen" onClick={() => {}} />
                    <Button title="Versicherungsschaden melden" onClick={() => {}} />
                    <Button title="Problem mit Energieverträgen melden" onClick={() => {}} />
                    <Button title="Vertrag ändern" onClick={() => {}} />
                    <Button title="Sonstiges" onClick={() => {}} />
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
