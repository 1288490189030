/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is contracts store to store all customer's contracts
 */

import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Contract from '../models/entity/Contract';
import config from '../config';
import { INSURANCE_CONTRACTS_CATEGORIES, STROM_CONTRACTS_CATEGORIES } from '../const/contract';
import { TEMP_CONTRACT_TYPE } from '../const/tempContractType';

class ContractsStore {
    rootStore;
    /*
     * this is contract to add in onboarding
     */
    contractTemp = new Contract();

    tempContractType = '';

    /*
     * contracts
     */
    contracts = new Map();

    /*
     * id of new insurance contracts which was created during onboarding
     */
    onboardingSignedInsuranceContracts = [];

    /*
     * id of new energy contracts which was created during onboarding
     */
    onboardingSignedEnergyContracts = [];

    /*
     * contract ids for broker
     */
    checkedIds = [];

    /*
     * is persist store loaded
     */
    isLoaded = false;

    /*
     * is contract loaded
     */
    isLoadedContracts = false;

    FilterCategories = INSURANCE_CONTRACTS_CATEGORIES;

    /*
     * This is constructor
     */
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        makePersistable(this, {
            name: 'BancassuranceContractsStore',
            properties: ['checkedIds'],
            storage: window.localStorage,
            removeOnExpiration: true,
            expireIn: config.storesExpiretion.contracts,
            debugMode: false,
        }).then(
            action(() => {
                this.setIsLoaded(true);
            })
        );
    }

    /*
     * get number of contracts
     */
    get getNumberOfContracts() {
        return this.contracts.size || 0;
    }

    /*
     * get all payments value
     */
    get getPayments() {
        return 0;
    }

    get filterCategories() {
        return this.FilterCategories;
    }

    /*
     * load store
     */
    async loadStore() {
        const res = await Contract.getContracts();
        if (res.status && Array.isArray(res.data)) {
            this.contracts = new Map();
            res.data.map((obj) => {
                const contract = new Contract(obj);
                this.contracts.set(contract.idValue, contract);
            });
        }
        this.setLoadedContracts(true);
        return res;
    }

    /*
     * set loaded contracts
     */
    setLoadedContracts(value) {
        this.isLoadedContracts = value;
    }

    /*
     * set checkedIds
     */
    setCheckedIds(checkedIds) {
        this.checkedIds = checkedIds;
    }

    /*
     * set contract
     */
    setContract(contractId, contract) {
        this.contracts.set(contractId, contract);
    }

    setFilterCategories(categories) {
        this.FilterCategories = categories;
    }

    getInsuranceContracts(isTemp) {
        if (this.contracts.size) {
            const insuranceProductIds = this.rootStore.DataStore.getProductsByCategories(INSURANCE_CONTRACTS_CATEGORIES);
            const filteredContracts = Array.from(this.contracts.values()).filter((contract) => insuranceProductIds.includes(contract.productType));

            return isTemp ? filteredContracts.filter((contract) => contract.isTemp) : filteredContracts;
        }

        return [];
    }

    getStromContracts(isTemp) {
        if (this.contracts.size) {
            const stromProductsIds = this.rootStore.DataStore.getProductsByCategories(STROM_CONTRACTS_CATEGORIES);
            const filteredContracts = Array.from(this.contracts.values()).filter((contract) => stromProductsIds.includes(contract.productType));

            return isTemp ? filteredContracts.filter((contract) => contract.isTemp) : filteredContracts;
        }

        return [];
    }

    getTempContracts() {
        return Array.from(this.contracts.values()).filter((contract) => contract.isTemp);
    }

    /*
     * get filtered contracts for contract list page
     */
    getFilteredContracts(type, searchText, statusContract, order) {
        const ret = [];
        searchText = searchText || '';
        statusContract = statusContract || 'active';
        order = order || 'asc';
        const typeContracts = type === 'insurance' ? this.getInsuranceContracts(false) : this.getStromContracts(false);
        for (const contract of typeContracts) {
            const productTitle = this.rootStore.DataStore.getProductTypeTitle(contract.productTypeValue);
            const textToSearch = productTitle ? productTitle.toLowerCase() : '';
            if ((!searchText || (searchText && textToSearch.indexOf(searchText.toLowerCase()) !== -1)) && contract.statusValue === statusContract) {
                ret.push(contract);
            }
        }
        ret.sort((objA, objB) => {
            const objATitle = this.rootStore.DataStore.getProductTypeTitle(objA.productTypeValue);
            const objBTitle = this.rootStore.DataStore.getProductTypeTitle(objB.productTypeValue);
            const titleA = order === 'asc' ? objATitle : objBTitle;
            const titleB = order === 'asc' ? objBTitle : objATitle;
            return !!titleA && titleA.localeCompare(titleB, 'de');
        });

        return ret;
    }

    /*
     * set loaded store or no
     */
    setIsLoaded(value) {
        runInAction(() => {
            this.isLoaded = value;
        });
    }

    /*
     * clear store contracts store
     */
    clearStore() {
        this.contracts = new Map();
        this.contractTemp = new Contract();
        runInAction(() => {
            this.setCheckedIds([]);
        });
        this.setLoadedContracts(false);
    }

    /*
     * get onboarding contracts array with manual contracts or finapi contracts
     */
    getOnboardingContracts(isManual) {
        const contracts = [];
        for (const [, contract] of this.contracts) {
            if (contract.isTemp) {
                if (contract.isManual && isManual) {
                    contracts.push(contract);
                } else if (!contract.isManual && !isManual) {
                    contracts.push(contract);
                }
            }
        }
        return contracts;
    }

    getDetailedContract(contractId) {
        const contract = this.contracts.get(contractId);

        if (contract.productType) {
            contract.productTitle = this.rootStore.DataStore.getProductTypeTitle(contract.productType);
        }
        contract.providerTitle = contract.providerWithCustomValue ? this.rootStore.DataStore.getProviderTitle(contract.providerWithCustomValue) : contract.customProvider;
        contract.providerLogoUrl = this.rootStore.DataStore.getProviderLogoUrl(contract.provider);
        contract.statusTitle = this.rootStore.DataStore.getContractStatusTitle(contract.statusValue);

        return contract;
    }

    getDetailedContractsList() {
        const contracts = new Map();
        const productIds = this.rootStore.DataStore.getProductsByCategories(this.FilterCategories);
        const filteredContracts = Array.from(this.contracts.values()).filter((contract) => productIds.includes(contract.productType));

        // for (const contract of filteredContracts) {
        //     const newContract = this.getDetailedContract(contract.idValue);
        //     contracts.set(newContract.idValue, newContract);
        // }

        filteredContracts.map((contract) => {
            const newContract = this.getDetailedContract(contract.idValue);
            newContract && contracts.set(contract.idValue, contract);
        });

        return contracts;
    }

    /*
     * get contract
     */
    getContract(contractId) {
        if (this.contracts.has(contractId)) {
            return this.contracts.get(contractId);
        }
        return new Contract();
    }

    /*
     * start process to add a contract
     */
    startAddingContract() {
        this.contractTemp = Contract.getTempContract();
    }

    /*
     * start process to edit a contract
     */
    editContract(contractId) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            this.contractTemp = Contract.copyContract(contract);
            this.contractTemp.setProvider({ provider: '', customProvider: contract.customProviderValue });
            return true;
        }
        return false;
    }

    /*
     * delete contract from the list
     */
    deleteContract(contractId) {
        if (this.contracts.has(contractId)) {
            // const contract = this.contracts.get(contractId);
            // if (!contract.isManual) {
            //     this.contracts.delete(contractId);
            // } else {
            //     contract.setIsDeleted(true);
            //     this.contracts.set(contractId, contract);
            // }

            // return true;
            this.contracts.delete(contractId);
        }
        return false;
    }

    /*
     * delete contract from the database
     */
    async removeContract(contractId) {
        if (this.contracts.has(contractId)) {
            const res = await Contract.remove(contractId);
            if (res.status) {
                this.contracts.delete(contractId);
            }
            return res;
        }
        return { status: false, msg: 'No Contract' };
    }

    /*
     * update contract
     */
    async updateContract(contractId, fields) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            const res = await contract.update(fields);
            if (res.status) {
                this.setContract(contractId, contract);
            }
            return res;
        }
        return { status: false, msg: 'No Contract' };
    }

    /*
     * undelete contract in the list
     */
    undeleteContract(contractId) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            contract.setIsDeleted(false);
            this.contracts.set(contractId, contract);
            return true;
        }
        return false;
    }

    /*
     * add temp contract into contracts
     */
    addTempContract() {
        if (!this.contractTemp.idValue) {
            return null;
        }
        const { contractTemp } = this;
        this.contracts.set(contractTemp.idValue, contractTemp);
        this.contractTemp = new Contract();
        return true;
    }

    /*
     * set product type of temp contract
     */
    setProductTypeForAddingContract(val) {
        const { contractTemp } = this;
        const product = this.rootStore.DataStore.getProduct(val);

        contractTemp.setProductType(val);
        contractTemp.setForecastTokenValues(product.forecastTokenValues);
        this.contractTemp = contractTemp;
    }

    /*
     * set provider of temp contract
     */
    setProviderForAddingContract({ provider, customProvider, isBroker }) {
        const { contractTemp } = this;
        contractTemp.setProvider({ provider, customProvider });
        contractTemp.setIsBroker(isBroker);
        this.contractTemp = contractTemp;
    }

    setTempContractType(type) {
        this.tempContractType = type;
    }

    /*
     * set field of the contract
     */
    setFieldForAddingContract({ number }) {
        const { contractTemp } = this;
        if (number) {
            contractTemp.setNumber(number);
        }
        this.contractTemp = contractTemp;
    }

    /*
     * set field of the contract
     */
    addDocumentsForAddingContract(documents) {
        const { contractTemp } = this;
        contractTemp.addNewDocuments(documents);
        this.contractTemp = contractTemp;
    }

    /*
     * set temp contract for activating as a broker
     */
    addTempContractForBroker(contractIds) {
        for (const [, contract] of this.contracts) {
            if (contractIds.indexOf(contract.idValue) !== -1) {
                contract.setChecked(true);
                this.contracts.set(contract.idValue, contract);
            }
        }
    }

    setFinAPIData(contract, obj, accountData) {
        this.isManual = false;
        if (obj.contractId) {
            contract.contractTempID = obj.contractId;
        }

        //set productTypeid
        let productType = obj.contractType ? this.rootStore.DataStore.getListElementIdFromFinAPI('product', obj.contractType) : '';
        productType = productType || 'other_insurances';
        contract.setProductType(productType);

        //set providerId
        const provider = obj.companyDetails && obj.companyDetails.companyId ? this.rootStore.DataStore.getListElementIdFromFinAPI('provider', obj.companyDetails.companyId) : '';
        const customProvider = provider ? '' : obj.companyName;
        contract.setProvider({ provider, customProvider });

        const isBrokerPossible = this.rootStore.DataStore.getIsPossibleBroker(productType, provider);
        contract.setIsBroker(isBrokerPossible);

        //set status
        // let status = obj.contractStatus ? DataStore.getListElementIdFromFinAPI('status', obj.contractStatus) : '';
        // status = status ? status : 'active';
        // this.setStatus(status);

        //set paymentMethod
        const paymentMethod = obj.billing && obj.billing.period ? this.rootStore.DataStore.getListElementIdFromFinAPI('paymentMethod', obj.billing.period) : '';
        contract.setPaymentMethod(paymentMethod);

        //set price
        const price = obj.billing && obj.billing.lastBillingAmount ? obj.billing.lastBillingAmount : 0;
        contract.setPrice(price);

        //set number
        const number = obj.contractNumber ? obj.contractNumber : '';
        contract.setNumber(number);

        //set startDate
        const startDate = obj.billing && obj.billing.lastBillingDate ? obj.billing.lastBillingDate : '';
        contract.setStartDate(startDate);

        //set bankName and iban
        let bankName = '';
        let iban = '';
        let accountOwner = '';
        if (accountData.length === 1) {
            const account = accountData[0];
            bankName = account.bankName ? account.bankName : '';
            iban = account.accountIban ? account.accountIban : '';
            accountOwner = account.accountId ? account.accountId : '';
        } else if (accountData.length > 1) {
            for (let i = 0; i < accountData.length; i++) {
                const account = accountData[i];
                bankName = account.bankName ? account.bankName : '';
                iban = account.accountIban ? account.accountIban : '';
                accountOwner = account.accountId ? account.accountId : '';
                let isFound = false;
                if (bankName && iban && account.transactions && account.transactions.length) {
                    for (let j = 0; j < account.transactions.length; j++) {
                        const transaction = account.transactions[j];
                        if (transaction && transaction.contractIds && transaction.contractIds.length && transaction.contractIds.indexOf(contract.id) !== -1) {
                            isFound = true;
                            break;
                        }
                    }
                }
                if (isFound) {
                    break;
                }
            }
        }
        contract.setBankName(bankName);
        contract.setIBAN(iban);
        contract.setFinAPIAccountOwner(accountOwner);

        return contract;
    }

    /*
     * set finApi contracts
     */
    setFinAPIContracts(data) {
        if (data.reports.contractsInsurance.contractsData) {
            const accountData = data.hasOwnProperty('accountData') ? data.accountData : [];
            data.reports.contractsInsurance.contractsData.map((obj) => {
                const contract = Contract.getTempContract();
                const newContract = this.setFinAPIData(contract, obj, accountData);
                this.contracts.set(contract.idValue, newContract);
            });
        }
    }

    /*
     * save temp contracts with documents into server and set chackedIds for broker activation
     */
    async saveTempContracts(customerId) {
        const arr = [];
        for (const [, contract] of this.contracts) {
            if (contract.isTemp) {
                const serverObj = contract.getServerObject();
                delete serverObj.contract.contractId;
                delete serverObj.brokerTransmission;
                if (customerId) {
                    serverObj.contract.customerId = customerId;
                }
                arr.push(serverObj);
            }
        }
        let res = { status: true };
        if (arr.length) {
            const tempIds = [];
            res = await Contract.saveMultiContracts(arr);
            if (res.status && Array.isArray(res.data)) {
                for (const contractData of res.data) {
                    if (contractData.contractId && contractData.contractTempID && this.contracts.has(contractData.contractTempID)) {
                        const contract = this.contracts.get(contractData.contractTempID);
                        contract.setId(contractData.contractId);

                        this.contracts.set(contractData.contractId, contract);
                        this.contracts.delete(contractData.contractTempID);

                        await contract.saveNewDocuments();

                        if (contract.isChecked) {
                            tempIds.push(contractData.contractId);
                        }
                    }
                }
            }
            this.setCheckedIds(tempIds);
            if (this.tempContractType === TEMP_CONTRACT_TYPE.INSURANCE) {
                this.onboardingSignedInsuranceContracts = [
                    ...this.onboardingSignedInsuranceContracts,
                    ...tempIds,
                ];
            }

            if (this.tempContractType === TEMP_CONTRACT_TYPE.ENERGY) {
                this.onboardingSignedEnergyContracts = [
                    ...this.onboardingSignedEnergyContracts,
                    ...tempIds,
                ];
            }
        }
        return res;
    }

    /*
     * update number of documents for contract
     * @param contractId - int
     * @param diff - int - difference how much we need to add to documents' number
     */
    updateDocumentNumber(contractId, diff) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            contract.changeDocumentsNumber(diff);
            this.contracts.set(contractId, contract);
            return true;
        }
        return false;
    }

    clearOnboardingContracts() {
        this.onboardingSignedInsuranceContracts = [];
        this.onboardingSignedEnergyContracts = [];
    }

    /*
     * activate broker for checkedIds array
     */
    async activateBrokerForCheckedContracts(customerId) {
        if (this.checkedIds.length) {
            const res = await this.activateBrokerForContracts(this.checkedIds, customerId);

            return res;
        }
        return { status: false, msg: 'There is no checked contracts' };
    }

    /*
     * activate broker for contractIds array
     */
    async activateBrokerForContracts(contractIds, customerId) {
        const res = await Contract.activateBrokerForContracts(contractIds, this.tempContractType, customerId);
        if (res.status && res.data && Array.isArray(res.data)) {
            res.data.map((contract) => {
                if (!!contract && this.contracts.has(contract.contractId)) {
                    const contractObj = this.contracts.get(contract.contractId);
                    contractObj.setFields({ brokerStatus: 'on_work' });
                    this.setContract(contract.contractId, contractObj);
                }
            });
        }
        return res;
    }
}

export default ContractsStore;
