/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractAdd
 * path: /onboarding/vertrag/import
 *
 * this is a page for selecting how to add contracts - through finApi or manual - in onboarding proccess
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/elements/Loading';
import Header from '../../components/theme/HeaderV2';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import logo from '../../assets/V2/conunityLogo.svg';
import '../../styles/V1/app.scss';
import checkMark from '../../assets/V2/checkMarkBlue.svg';
import checkMarkGreen from '../../assets/V2/checkMarkGreen.svg';
import Button from '../../components/V2/Button';
import { TEMP_CONTRACT_TYPE } from '../../const/tempContractType';

const ContractAddPage = observer(({ ContractsStore, UserStore }) => {
    //state variables
    const [isLoading] = useState(false);
    const { isAuthorized } = UserStore;

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    const isInsuranceContractsExist = ContractsStore.onboardingSignedInsuranceContracts.length > 0;
    const isStromContractsExist = ContractsStore.onboardingSignedEnergyContracts.length > 0;

    //ref variable for popup
    const refAlert = useRef();

    const handleInsuranceClick = () => {
        if (isInsuranceContractsExist) {
            return;
        }

        navigate(pageUrl.onboardingContractList);
        ContractsStore.setTempContractType('insurance');
    };

    const handleUtilitiesClick = () => {
        if (isStromContractsExist) {
            return;
        }

        navigate(pageUrl.onboardingContractList);
        ContractsStore.setTempContractType(TEMP_CONTRACT_TYPE.ENERGY);
    };

    const handleNext = () => {
        ContractsStore.clearOnboardingContracts();

        if (isAuthorized) {
            navigate(pageUrl.secureDashboard);
        } else {
            navigate(pageUrl.onboardingBrokerSuccess);
        }

        //TODO: redirect to sign pages if customer didn't sign any broker agreements
        // redirect to user data page if customer isn't authorized
        // add to Route Onboarding
    };

    const isNextButton = isInsuranceContractsExist || isStromContractsExist;

    return (
        <div className="main-wrapper no-background">
            <Header title="Account einrichten" noIcon noMenu backButton />
            <div className="main-content-new contract-add-page no-scroll">
                <div className="header-section">
                    <img src={logo} alt="logo" className="title-logo" />
                    <div className="page-title">{trans.t('onboardingContractAddPage', 'pageTitle')}</div>
                </div>
                <div className="description">
                    {trans.t('onboardingContractAddPage', 'description')}
                </div>
                <div className="buttons-container">
                    <button
                        type="button"
                        className="contract-button insurance"
                        onClick={handleInsuranceClick}
                    >
                        <span className="button-text">{trans.t('onboardingContractAddPage', 'insuranceButton')}</span>
                        <span className="button-icon">
                            {isInsuranceContractsExist ? <img src={checkMark} alt="check" className="check-mark" /> : '+'}
                        </span>
                    </button>
                    <button
                        type="button"
                        className="contract-button utilities"
                        onClick={handleUtilitiesClick}
                    >
                        <span className="button-text">{trans.t('onboardingContractAddPage', 'utilitiesButton')}</span>
                        <span className="button-icon">
                            {isStromContractsExist ? <img src={checkMarkGreen} alt="check" className="check-mark" /> : '+'}
                        </span>
                    </button>
                </div>
                {/* <Tooltip position="bottom">
                    {trans.t('onboardingContractAddPage', 'tooltipText')}
                </Tooltip> */}
                <div style={{ flex: 1 }} />
                {isNextButton && (
                    <Button
                        title={trans.t('onboardingContractAddPage', 'nextButton')}
                        role="btnNext"
                        big
                        green
                        onClick={handleNext}
                    />
                )}
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractAddPage;
