/**
 * Created by Max Gornostayev on 04/29/22
 *
 * path: /auth/aktivieren
 * id: authSignupStepOne
 *
 * this is a page that shows first-step registration form, after this page we need go to second-step page
 * and enter code from SMS
 */

import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import InputText from '../../components/forms/InputText';
import Select from '../../components/forms/Select';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import ButtonV2 from '../../components/V2/Button';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import SignupSteps from '../../components/elements/SignupSteps';
import Tooltip from '../../components/V2/Tooltip';
import pageUrl from '../../const/pages';
import Utils from '../../lib/Utils';
import Validation from '../../lib/Validation';
import trans from '../../trans';
import '../../styles/V1/app.scss';
import '../../styles/V1/auth.scss';

const SignupStepOnePage = observer(({ UserStore }) => {
    //get contractTemp
    const { customer, isCompany } = UserStore;

    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(customer.emailValue);
    const [phoneCode, setPhoneCode] = useState(customer.phoneCodeValue);
    const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumberValue);

    //ref variable
    const refEmail = useRef();
    const refPhoneCode = useRef();
    const refPhoneNumber = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //handling next button click
    const next = async () => {
        let formValidated = true;

        let validObj = refEmail.current.validate();
        if (!validObj.isValid) {
            refEmail.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        if (!isCompany) {
            validObj = refPhoneNumber.current.validate();
            if (!validObj.isValid) {
                refPhoneNumber.current.setValidation(validObj.isValid, validObj.validateMsg);
                formValidated = false;
            }
        }

        if (!formValidated) {
            return false;
        }

        setIsLoading(true);
        const res = await UserStore.updateEmailPhone({ email, phoneCode, phoneNumber });
        if (!res.status && res.errorId !== 'otp_is_already_sent') {
            setIsLoading(false);
            refAlert.current.error(res.msg);
            return false;
        }
        setIsLoading(false);
        if (isCompany) {
            navigate(pageUrl.authSignupStepThree);
        } else {
            navigate(pageUrl.authSignupStepTwo);
        }

        return true;
    };

    return (
        <div className="main-wrapper no-background onboarding-contract-edit-page">
            <Header backButton noMenu title={trans.t('signupStepOnePage', 'h1')} />
            <div className="content" style={{ height: '100%', overflowY: 'auto' }}>
                <div className="wrapper-big" style={{ overflowY: 'auto' }}>
                    <Tooltip position="top">
                        {trans.t('signupStepOnePage', 'expertMsg')}
                    </Tooltip>
                    <SignupSteps currentStep={1} />
                    {!isCompany && (
                        <Select
                            ref={refPhoneCode}
                            title={trans.t('signupStepOnePage', 'phoneCode')}
                            options={Utils.getPhoneCodeList()}
                            onChange={(val) => setPhoneCode(val)}
                            defaultValue={customer.phoneCodeValue}
                        />
                    )}
                    {!isCompany && (
                        <InputText
                            ref={refPhoneNumber}
                            role="phoneNumberTestId"
                            title={trans.t('signupStepOnePage', 'phoneNumber')}
                            desc={trans.t('signupStepOnePage', 'phoneNumberDesc')}
                            defaultValue={customer.phoneNumberValue}
                            onChange={(val) => setPhoneNumber(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.phone(val, phoneCode)}
                        />
                    )}
                    <InputText
                        ref={refEmail}
                        role="emailTestId"
                        title={trans.t('signupStepOnePage', 'email')}
                        desc={trans.t('signupStepOnePage', 'emailDesc')}
                        defaultValue={customer.emailValue}
                        onChange={(val) => setEmail(val)}
                        className="margin-top-16"
                        validateFunc={(val) => Validation.email(val)}
                    />
                    <div className="bottom-list-text">
                        <ul>
                            <li>{trans.t('signupStepOnePage', 'simpleTextBottomLiFirst')}</li>
                            <li>{trans.t('signupStepOnePage', 'simpleTextBottomLiSecond')}</li>
                        </ul>
                    </div>
                </div>
                <div style={{ height: '200px' }} />
            </div>
            <Bottom>
                <ButtonV2
                    title={trans.t('signupStepOnePage', 'btnNext')}
                    onClick={next}
                    green
                />
            </Bottom>
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignupStepOnePage;
