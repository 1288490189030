import React from 'react';

export const Switcher = ({ firstTitle, secondTitle, firstFClick, secondClick, active, firstColor, secondColor }) => {
    const activeColor = active === 'first' ? firstColor : secondColor;

    return (
        <div className="switcher-wrapper" style={{ borderColor: activeColor }}>
            <div
                className={`switcher-item left ${active === 'first' ? 'active' : ''}`}
                style={{
                    backgroundColor: `${active === 'first' ? firstColor : 'white'}`,
                    color: `${active === 'second' ? secondColor : 'white'}`
                }}
                onClick={firstFClick}
            >
                {firstTitle}
            </div>
            <div
                className={`switcher-item right ${active === 'second' ? 'active' : ''}`}
                style={{
                    backgroundColor: `${active === 'second' ? secondColor : 'white'}`,
                    color: `${active === 'first' ? firstColor : 'white'}`
                }}
                onClick={secondClick}
            >
                {secondTitle}
            </div>
        </div>
    );
};
