/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a popup for all
 */

import React from 'react';
import trans from '../../trans';
import ButtonV2 from '../V2/Button';

export default class Popup extends React.Component {
    /*
     * right button click handler
     */
    rightClick() {
        this.close();

        if (this.props.rightFunc) {
            this.props.rightFunc();
        }
    }

    /*
     * right button click handler
     */
    leftClick() {
        this.close();

        if (this.props.leftFunc) {
            this.props.leftFunc();
        }
    }

    /*
     * public function that is used to show popup
     */
    show() {
        this.setState({ isShow: true });
    }

    /*
     * public function that is used to hide popup
     */
    close() {
        this.setState({ isShow: false });
    }

    /*
     * rendering
     */
    render() {
        if (!this.props.isShow) {
            return null;
        }
        let className = 'popup popup-common';
        if (this.props.isAutoHeight) {
            className += ' popup-auto-height';
        }
        return (
            <div className={className}>
                <div className="container">
                    {!!this.props.title && (
                        <div className="container-top">
                            <div className="title">{this.props.title}</div>
                        </div>
                    )}
                    <div className="container-inner">
                        <div className="scroll">{this.props.children}</div>
                    </div>
                    <div className="container-bottom">
                        {!!this.props.leftBtnTitle && (
                            <ButtonV2
                                title={this.props.leftBtnTitle}
                                onClick={() => this.leftClick()}
                                className="left-btn"
                                isWhite
                                role="btnLeft"
                                withoutArrow
                            />
                        )}
                        <ButtonV2
                            title={this.props.rightBtnTitle || trans.t('common', 'ok')}
                            onClick={() => this.rightClick()}
                            className="right-btn"
                            role="btnRight"
                            red={this.props.redButton}
                            green={this.props.greenButton}
                            withoutArrow
                        />
                    </div>
                </div>
            </div>
        );
    }
}
