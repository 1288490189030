import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
// import notifications from '../../assets/header/notifications.svg';
import supportAgent from '../../assets/header/support-agent.svg';
import menu from '../../assets/header/menu.svg';
import SlideMenu from './SlideMenu';
import { useNavigate } from 'react-router-dom';
import chevronRight from '../../assets/chevron-right.svg';
import pageUrl from '../../const/pages';

const Header = ({ backButton, title, noMenu, noIcon, backFunc, noSupport }) => {
    const navigate = useNavigate();
    const [isOpenedSlide, setIsOpenedMenu] = useState(false);

    const openSlideMenu = () => {
        setIsOpenedMenu(true);
    };

    const navigateToSupport = () => {
        navigate(pageUrl.support);
    };

    return (
        <div className="header-wrapper">
            {backButton ? (
                <img src={chevronRight} className="back-button" onClick={backFunc || (() => navigate(-1))} />
            ) : (
                <a href="/" className="logo">
                    {!noIcon && <img src={logo} alt="logo" className="logo" />}
                </a>
            )}
            <div className={`header-title ${!noMenu ? 'with-menu' : ''}`}>{title}</div>
            <div className="right-side">
                {/* <a href="/" className="logo">
                        <img src={notifications} alt="logo" className="logo" />
                    </a> */}
                {!noSupport && (
                    <div className="header-logo" onClick={navigateToSupport}>
                        <img src={supportAgent} alt="logo" className="header-logo" />
                    </div>
                )}
                {!noMenu && (
                    <div className="header-logo" onClick={openSlideMenu}>
                        <img src={menu} alt="logo" className="logo" />
                    </div>
                )}
            </div>
            <SlideMenu isOpened={isOpenedSlide} closeFunc={() => setIsOpenedMenu(false)} />
        </div>
    );
};

export default Header;
